// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addApplianceRowTableRow {
    cursor: pointer;
    border-bottom: 1px solid #eee;
}

.addApplianceRowTableRow:hover {
    background-color: rgba(220, 220, 245, 25%);
}

.addApplianceRowImageContainer {
    display: block;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background: linear-gradient(to top left, rgb(78, 78, 172) 30%,  lightblue 100%);
    overflow: hidden;
    border: 1px solid #666;
    transition: width 0.2s  ease-in-out, height 0.2s  ease-in-out, transform 0.3s ease-in-out;
}

.addApplianceImage {
    margin: 20px 0px 0px 20px;
    height: 80px;
    width: 80px;
    overflow: hidden;
}

.addApplianceRowTableCell {
    font-size: 13pt;
    border: none;
}

.addApplianceRowTableCell.image {
    width: 20%;
}

.addApplianceRowTableCell.model {
    width: 70%;
}

.addApplianceRowTableCell.spacer {
    width: 10%;
}`, "",{"version":3,"sources":["webpack://./src/css/components/ConsumerAddApplianceRow.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,cAAc;IACd,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,+EAA+E;IAC/E,gBAAgB;IAChB,sBAAsB;IACtB,yFAAyF;AAC7F;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd","sourcesContent":[".addApplianceRowTableRow {\n    cursor: pointer;\n    border-bottom: 1px solid #eee;\n}\n\n.addApplianceRowTableRow:hover {\n    background-color: rgba(220, 220, 245, 25%);\n}\n\n.addApplianceRowImageContainer {\n    display: block;\n    width: 96px;\n    height: 96px;\n    border-radius: 50%;\n    background: linear-gradient(to top left, rgb(78, 78, 172) 30%,  lightblue 100%);\n    overflow: hidden;\n    border: 1px solid #666;\n    transition: width 0.2s  ease-in-out, height 0.2s  ease-in-out, transform 0.3s ease-in-out;\n}\n\n.addApplianceImage {\n    margin: 20px 0px 0px 20px;\n    height: 80px;\n    width: 80px;\n    overflow: hidden;\n}\n\n.addApplianceRowTableCell {\n    font-size: 13pt;\n    border: none;\n}\n\n.addApplianceRowTableCell.image {\n    width: 20%;\n}\n\n.addApplianceRowTableCell.model {\n    width: 70%;\n}\n\n.addApplianceRowTableCell.spacer {\n    width: 10%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
