import React from 'react';
import { useNavigate } from 'react-router-dom'; // If using react-router-dom for navigation

const PrivacyPolicy = () => {
  const navigate = useNavigate(); // Hook for navigation (optional)

  return (
    <div className="max-w-4xl mx-auto my-10 px-6">
      {/* Contact and Back to Home Button */}
      
      <button
          className="bg-gray-200 mt-4 text-black px-4 py-2 rounded-lg shadow-md hover:bg-gray-300"
          onClick={() => navigate('/')} // Replace with actual navigation method
        >
          Ladle
        </button>
        <h1 className="mt-4 text-3xl font-bold text-gray-800 mb-4">
        Privacy Policy
      </h1>
      <div className="flex mt-3 justify-between items-center mb-8">
        
        <div>
          <h2 className="text-xl font-semibold text-gray-800">
            Contact Information
          </h2>
          <p className="text-gray-700">
            Email:{' '}
            <a
              href="mailto:info@ladle.cooking"
              className="text-blue-600 underline"
            >
              info@ladle.cooking
            </a>
          </p>
          <p className="text-gray-700">
            Address: 555 Mission Rock Street, Suite 506, San Francisco, CA
            94158
          </p>
        </div>
       
      </div>

      {/* Privacy Policy Content */}
     
      <p className="text-gray-600 mb-2">
        <strong>Privacy Policy Last updated: [11/21/24]</strong>
      </p>
      <p className="text-gray-700 mb-6">
        Welcome to <span className="font-semibold"> <a href='https://app.ladle.cooking'>Ladle</a> </span>! We
        value your privacy and are committed to protecting your personal data.
        This Privacy Policy explains how we collect, use, and safeguard your
        information.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
        1. Information We Collect
      </h2>
      <p className="text-gray-700 mb-4">
        We collect the following types of information:
      </p>
      <ul className="list-disc list-inside text-gray-700 mb-6 space-y-2">
        <li>
          <strong>User Information:</strong> Name, email address.
        </li>
        <li>
          <strong>Device Information:</strong> IP address, location data.
        </li>
        <li>
          <strong>Cookies and Tracking:</strong> Information collected through
          cookies or similar technologies.
        </li>
        <li>
          <strong>Financial Data:</strong> Currently we do not collect financial
          data.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
        2. How We Collect Data
      </h2>
      <ul className="list-disc list-inside text-gray-700 mb-6 space-y-2">
        <li>
          <strong>Provided by You:</strong> When you sign up or interact with
          our app.
        </li>
        <li>
          <strong>Automatically Collected:</strong> Through device usage,
          cookies, and tracking technologies.
        </li>
        <li>
          <strong>Through Permissions:</strong> When you grant access to
          features like your camera or microphone.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
        3. Why We Collect Your Data
      </h2>
      <p className="text-gray-700 mb-4">We use your data to:</p>
      <ul className="list-disc list-inside text-gray-700 mb-6 space-y-2">
        <li>Provide and improve app functionality.</li>
        <li>
          Analyze usage and improve user experience (via analytics tools).
        </li>
        <li>Serve relevant advertisements (via advertising networks).</li>
        <li>Comply with legal obligations.</li>
      </ul>

      <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
        4. Sharing Your Data
      </h2>
      <p className="text-gray-700 mb-4">
        We may share your data with third parties:
      </p>
      <ul className="list-disc list-inside text-gray-700 mb-2 space-y-2">
        <li>
          <strong>Analytics Providers:</strong> To understand app usage and
          improve services.
        </li>
        <li>
          <strong>Advertising Networks:</strong> To display personalized ads.
        </li>
      </ul>
      <p className="text-gray-700 mb-6">
        These third parties are required to comply with data protection laws.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
        5. Your Rights
      </h2>
      <p className="text-gray-700 mb-4">
        If you are a resident of the U.S., Canada, or the EU, you have the
        following rights:
      </p>
      <ul className="list-disc list-inside text-gray-700 mb-2 space-y-2">
        <li>
          <strong>Access and Update:</strong> Request access to or correction of
          your data.
        </li>
        <li>
          <strong>Data Deletion:</strong> Request deletion of your personal
          information.
        </li>
        <li>
          <strong>Opt-Out:</strong> Opt out of data collection for analytics or
          advertising.
        </li>
      </ul>
      <p className="text-gray-700 mb-6">
        To exercise these rights, contact us at{' '}
        <a
          href="mailto:info@ladle.cooking"
          className="text-blue-600 underline"
        >
          info@ladle.cooking
        </a>
        .
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
        6. Cookies and Tracking
      </h2>
      <p className="text-gray-700 mb-4">We use cookies to:</p>
      <ul className="list-disc list-inside text-gray-700 mb-2 space-y-2">
        <li>Enhance app functionality.</li>
        <li>Provide personalized content and ads.</li>
      </ul>
      <p className="text-gray-700 mb-6">
        You can manage cookies in your browser settings or through our cookie
        management tool (if applicable).
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
        7. Data Retention
      </h2>
      <p className="text-gray-700 mb-6">
        We retain your personal data only as long as necessary for the purposes
        outlined in this policy or as required by law.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
        8. Data Security
      </h2>
      <p className="text-gray-700 mb-6">
        We use encryption and other security measures to protect your data.
        However, no method of transmission over the Internet is completely
        secure.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
        9. International Data Transfers
      </h2>
      <p className="text-gray-700 mb-6">
        Your data may be transferred and stored outside your country of
        residence. We ensure that appropriate safeguards are in place (e.g.,
        GDPR-compliant agreements).
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
        10. Legal Compliance
      </h2>
      <ul className="list-disc list-inside text-gray-700 mb-6 space-y-2">
        <li>
          <strong>CCPA:</strong> California residents can request a list of data
          collected and how it’s used.
        </li>
        <li>
          <strong>GDPR:</strong> EU residents can access, rectify, or delete
          their data, and we will seek explicit consent where required.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">
        11. Updates to This Policy
      </h2>
      <p className="text-gray-700 mb-6">
        We may update this Privacy Policy. Changes will be posted on this page,
        and significant updates will be communicated to you directly.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
