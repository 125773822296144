// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` 
.recipeListRowTableRow {
    cursor: pointer;
}

.recipeListRowTableRow:hover {
    background-color: rgba(220, 220, 245, 25%);
}

.recipeListImage {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    object-fit: cover;
    border: 1px solid goldenrod;
    overflow: hidden;
}

.recipeListRowTableCell {
    font-size: 13pt;
    border-bottom: 1px solid #eee;
}

.recipeListRowTableCell.image {
    width: 5%;
    border:none;
    padding: 12px 12px 12px 12px;
}

.recipeListRowTableCell.title {
    width: 28%;
}

.recipeListRowTableCell.title:hover {
    text-decoration: underline;
}

.recipeListRowTableCell.cuisine {
    width: 10%;
}

.recipeListRowTableCell.tags {
    width: 17%;
}

.recipeListRowTableCell.applianceTags {
    width: 11%;
}

.recipeListRowTableCell.views {
    width: 10%;
    padding-left: 24px;
}

.recipeListRowTableCell.date {
    width: 10%;
}

.recipeListRowTableCell.manage {
    width: 10%;
}`, "",{"version":3,"sources":["webpack://./src/css/components/RecipeListRow.css"],"names":[],"mappings":";AACA;IACI,eAAe;AACnB;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,2BAA2B;IAC3B,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI,SAAS;IACT,WAAW;IACX,4BAA4B;AAChC;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd","sourcesContent":[" \n.recipeListRowTableRow {\n    cursor: pointer;\n}\n\n.recipeListRowTableRow:hover {\n    background-color: rgba(220, 220, 245, 25%);\n}\n\n.recipeListImage {\n    border-radius: 50%;\n    height: 60px;\n    width: 60px;\n    object-fit: cover;\n    border: 1px solid goldenrod;\n    overflow: hidden;\n}\n\n.recipeListRowTableCell {\n    font-size: 13pt;\n    border-bottom: 1px solid #eee;\n}\n\n.recipeListRowTableCell.image {\n    width: 5%;\n    border:none;\n    padding: 12px 12px 12px 12px;\n}\n\n.recipeListRowTableCell.title {\n    width: 28%;\n}\n\n.recipeListRowTableCell.title:hover {\n    text-decoration: underline;\n}\n\n.recipeListRowTableCell.cuisine {\n    width: 10%;\n}\n\n.recipeListRowTableCell.tags {\n    width: 17%;\n}\n\n.recipeListRowTableCell.applianceTags {\n    width: 11%;\n}\n\n.recipeListRowTableCell.views {\n    width: 10%;\n    padding-left: 24px;\n}\n\n.recipeListRowTableCell.date {\n    width: 10%;\n}\n\n.recipeListRowTableCell.manage {\n    width: 10%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
