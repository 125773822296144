// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recipecard-parent-container {
  /*width: 100vw;
  padding-top: 72px;
  padding-bottom: 72px;*/
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/css/recipe.css"],"names":[],"mappings":"AAAA;EACE;;wBAEsB;EACtB,gBAAgB;AAClB","sourcesContent":[".recipecard-parent-container {\n  /*width: 100vw;\n  padding-top: 72px;\n  padding-bottom: 72px;*/\n  text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
