// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.outline.create-campaign {
    width: 220px;
    padding: 16px;
    color: #444;
    font-size: 13pt;
    border-color: #e8c254;
    background-color: #fff9ec;
}

button.outline.create-campaign:hover {
    width: 220px;
    padding: 16px;
    color: #444;
    font-size: 13pt;
    border-color: #e8c254;
    background-color: #f7ecd5;
}

button.outline.add-appliance {
    width: 220px;
    padding: 16px;
    color: #444;
    font-size: 13pt;
    border-color: #54a0e8;
    background-color: #cdcffa;
}

button.outline.add-appliance:hover {
    width: 220px;
    padding: 16px;
    color: #444;
    font-size: 13pt;
    border-color: #2983d7;
    background-color: #9284ff;
}`, "",{"version":3,"sources":["webpack://./src/css/sponsorships.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,WAAW;IACX,eAAe;IACf,qBAAqB;IACrB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,WAAW;IACX,eAAe;IACf,qBAAqB;IACrB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,WAAW;IACX,eAAe;IACf,qBAAqB;IACrB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,WAAW;IACX,eAAe;IACf,qBAAqB;IACrB,yBAAyB;AAC7B","sourcesContent":["button.outline.create-campaign {\n    width: 220px;\n    padding: 16px;\n    color: #444;\n    font-size: 13pt;\n    border-color: #e8c254;\n    background-color: #fff9ec;\n}\n\nbutton.outline.create-campaign:hover {\n    width: 220px;\n    padding: 16px;\n    color: #444;\n    font-size: 13pt;\n    border-color: #e8c254;\n    background-color: #f7ecd5;\n}\n\nbutton.outline.add-appliance {\n    width: 220px;\n    padding: 16px;\n    color: #444;\n    font-size: 13pt;\n    border-color: #54a0e8;\n    background-color: #cdcffa;\n}\n\nbutton.outline.add-appliance:hover {\n    width: 220px;\n    padding: 16px;\n    color: #444;\n    font-size: 13pt;\n    border-color: #2983d7;\n    background-color: #9284ff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
