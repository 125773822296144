import React, { useState, useEffect } from 'react';
import MenuBar from './MenubarChris';
import RecipeGrid from '../../components/RecipeGrid';
import { Player } from '@lottiefiles/react-lottie-player';
import loader from '../../loader/main_loading_animation.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTemperatureEmpty, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const WaitlistForm = () => {
    const [recipes, setRecipes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openRecipes, setOpenRecipes] = useState(true);
    const [recipesPerPage, setRecipesPerPage] = useState(8);

    useEffect(() => {
        // Load HubSpot script
        const script = document.createElement('script');
        script.src = "https://js-na2.hsforms.net/forms/embed/242225612.js";
        script.defer = true;
        document.body.appendChild(script);

        // Fetch recipes
        fetchRecipes();

        return () => {
            // Clean up the script when component unmounts
            if (document.body.contains(script)) {
                document.body.removeChild(script);
            }
        };
    }, []);

    const fetchRecipes = async () => {
        try {
            setLoading(true);
            const response = await fetch('https://api.ladle.cooking/api/v2/recipes?limit=300');
            const data = await response.json();
            setRecipes(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching recipes:', error);
            setLoading(false);
        }
    };

    const handleTagClick = (tag) => {
        console.log('Tag clicked:', tag);
    };

    // Get recipes to show based on open state and current page count
    const recipesToShow = openRecipes ? recipes.slice(0, recipesPerPage) : recipes.slice(0, 4);

    return (
        <>
            {!localStorage.getItem('userToken') ? <MenuBar /> : null}
            <div className="min-h-screen bg-white py-6 px-4 sm:px-6 lg:px-8">
                {/* Banner Image - Centered without stretching */}
                <div className="flex justify-center mb-8 overflow-hidden">
                    <img
                        src="/banner.png"
                        alt="Thank you for attending"
                        className="max-w-full h-[120px] md:h-[200px] object-contain"
                        style={{ maxWidth: '100%' }}
                    />
                </div>

                {/* Thank you message */}
                <div className="max-w-4xl mx-auto text-center py-6 px-4">

                    <h1 className="text-xl md:text-2xl font-bold mb-4 text-gray-900">
                        Join the

                        <span className="font-extrabold bg-gradient-to-r from-green-600 via-green-400 to-green-600 animate-gradient bg-[length:200%_auto] text-transparent bg-clip-text block md:inline"
                            style={{ animation: 'shine 3s linear infinite' }}>
                            &nbsp; Ladle Plus</span> Waitlist!

                    </h1>

                    <h1 className="text-xl md:text-2xl font-bold mb-4 text-gray-900">
                         Be the first to experience


                        <span className="font-extrabold bg-gradient-to-r from-green-600 via-green-400 to-green-600 animate-gradient bg-[length:200%_auto] text-transparent bg-clip-text block md:inline"
                            style={{ animation: 'shine 3s linear infinite' }}>
                            &nbsp; Ladle Plus—your personal AI sous chef. </span>Enjoy personalized recipes tailored to fit your diet, ingredient preferences, and what’s in your kitchen.   <span
                            className="font-extrabold bg-gradient-to-r from-green-600 via-green-400 to-green-600 animate-gradient bg-[length:200%_auto] text-transparent bg-clip-text block md:inline"
                             style={{ animation: 'shine 3s linear infinite' }}> Sign up now to get 10% </span> off your first year when we launch our premium subscription!

                    </h1>
                </div>

                {/* HubSpot Form */}
                <div className="max-w-3xl mx-auto mb-16">
                    <div className="space-y-6 bg-gray-50 p-8 rounded-lg shadow-md">

                        <div className="hs-form-frame"
                            data-region="na2"
                            data-form-id="ef67b86c-530b-4f62-8576-2f1d5480566e"
                            data-portal-id="242225612">
                        </div>

                        <p className="text-sm text-gray-500 mt-4 text-center">
                            By submitting this form, you agree to our <a href="/terms-of-service" className="text-green-600 hover:text-green-800">Terms of Service</a> and <a href="/privacy-policy" className="text-green-600 hover:text-green-800">Privacy Policy</a>. We'll never share your information.                        </p>
                    </div>
                </div>

                {/* Explore Recipes Section */}
                <div className="max-w-7xl mx-auto">
                    {loading ? (
                        <div style={{ minWidth: '100px', textAlign: 'center', verticalAlign: 'middle', alignItems: 'center', justifyContent: 'center', height: '400px', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ fontSize: '16pt', marginBottom: '32px' }}>Loading Recipes</p>
                            <Player
                                src={loader}
                                className="player w-[40%]"
                                loop
                                autoplay
                            />
                        </div>
                    ) : (
                        <div>
                            <div className='flex flex-col md:flex-row md:justify-between md:items-center md:mt-6 px-4 md:px-0'>
                                <div>
                                    <h1 className='text-2xl mb-3 font-bold text-[#0e4621]'>Explore Recipes</h1>
                                </div>

                            </div>

                            {recipesToShow.length > 0 ? (
                                <>
                                    <div className='p-4 md:p-0'>
                                        <RecipeGrid
                                            recipes={recipesToShow}
                                            type='consumer'
                                            handleTagClick={handleTagClick}
                                        />
                                    </div>

                                    {/* Pagination - Load More Button */}
                                    {openRecipes && recipesPerPage < recipes.length && (
                                        <div className="flex justify-center mt-2">
                                            <button
                                                onClick={() => setRecipesPerPage(prevCount => prevCount + 8)}
                                                className="text-center green"
                                            >
                                                Load More
                                            </button>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <p className='text-center p-3'>
                                    <FontAwesomeIcon icon={faTemperatureEmpty} style={{ paddingRight: '12px' }} />
                                    No recipes found
                                </p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default WaitlistForm;