import React, { Component } from 'react';
import config from "../utils/config.js";
import { AzureOpenAI } from 'openai';
import { X } from 'lucide-react';
import ReactGA from "react-ga4";

class ConsumerChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: this.props.initialMessage || "",
      userMessages: [{
        role: "assistant", content: "Hey there!"
      },
      {
        role: "assistant", content: "Want to add or replace an ingredient in this recipe? Just ask me!"
      }],
      promptMessages: [],
      loading: false,
      input: "",
      ingredientUpdated: false,
      oldrecipe: this.props.recipe
    }
  }
  componentDidMount() {
    console.log("we think", this.props.recipe);
    if (this.props.initialMessage != "") {
      this.changeRecipe(this.props.initialMessage)

      ReactGA.event({
        category: 'Template Prompt clicked',
        action: 'Button Click',
        label: this.props.initialMessage,
        value: localStorage.getItem("userId") ? localStorage.getItem("userId") : "anonymous",
      });
    }
  }
  async changeRecipe(e) {
    this.setState({
      loading: true,
      input: ""
    })
    let ingredients = []
    this.props.recipe.ingredients.forEach((ingredient) => {
      ingredients.push(ingredient.ingredientOptions[0])
    })
    console.log("final", ingredients)
    const promptTemplate = [];
    // const newIngredients = [];
    promptTemplate.push(...this.state.promptMessages, {
      role: 'assistant', content: `You're a professional sous chef assistant with an engaging, confident tone. Your role is to guide recipe modifications while maintaining culinary excellence.

      Core Process:
      1. Confirmation Step
         - Always confirm ingredient/cookware changes before proceeding
         - Verify user's intent clearly
      
      2. Evaluation Process
         When users request changes:
         - Check if it's a core ingredient
         - Assess if modification maintains recipe integrity
         - Suggest specific, real-world alternatives
         - Verify before implementing changes
      
      3. Response Guidelines
         For ingredient changes:
         - Format: "Ingredient: new: [precise measurement + ingredient], old: [id]"
         Example: "Ingredient: new: 1/2 cup white onion, old: 123"
      
         For cookware changes:
         - Format: "Cookware: new: [specific tool], old: [id]"
         Example: "Cookware: new: Cast Iron Skillet, old: 456"
      
      Key Rules:
      - Suggest only real, verified ingredients
      - Maintain recipe integrity
      - Provide precise measurements
      - Include specific cookware recommendations
      - Update related steps for any changes
      - Focus on one change at a time
      - Get user confirmation before modifications
      
      Quality Standards:
      - No generic substitutions
      - No invented ingredients
      - Only professional-grade recommendations
      - Clear, precise measurements
      - Logical substitutions that preserve dish quality
        remember your output when changing the recipe should be
       For ingredient changes:
         - Format: "Ingredient: new: [precise measurement + ingredient], old: [id]"
         Example: "Ingredient: new: 1/2 cup white onion, old: cup of green onion"
      
         For cookware changes:
         - Format: "Cookware: new: [specific tool], old: [id]"
         Example: "Cookware: new: Cast Iron Skillet, old: 456"

         the keywords Ingredient: and Cookware: are very important, and you should use them in your response.
           ` })
    promptTemplate.push(...this.state.promptMessages, { role: 'user', content: `The recipe Name is ${this.props.recipe.title}, and description is ${this.props.recipe.description},} their calories are Low: ${this.props.recipe.calories.low}, high: ${this.props.recipe.calories.high}}` }) //simple value

    ingredients.forEach((index, ingre) => {
      console.log("ingre are", index)
      promptTemplate.push(...this.state.promptMessages, { role: 'user', content: `The recipe steps are ${ingre}, ${index.name} their unique id is ${index.id}. consider this whole as one step` }) //simple value
    })
    this.props.recipe.steps.forEach((index, ingre) => {
      console.log("steps are", index)
      promptTemplate.push(...this.state.promptMessages, { role: 'user', content: `The recipe making instructions are ${index.text}` }) //simple value
    })
    this.props.recipe.cookware.forEach((index, ingre) => {
      console.log("cookware are", index)
      promptTemplate.push(...this.state.promptMessages, { role: 'user', content: `The recipe making cookware & appliances  are ${index.name} and their type is ${index.type} ` }) //simple value
    })
    if (this.state.userMessages.length > 0) {
      promptTemplate.push(...this.state.promptMessages, ...this.state.userMessages)
    }
    promptTemplate.push(...this.state.promptMessages, { role: 'user', content: `${this.state.message}` }) //simple value
    console.log(promptTemplate)
    this.setState({ userMessages: [...this.state.userMessages, { role: "user", content: this.state.message }] }) //simple value

    try {
      const azureApiKey = config.AZURE_KEY;

      const openai = new AzureOpenAI({
        endpoint: config.AZURE_ENDPOINT,
        apiKey: azureApiKey,
        dangerouslyAllowBrowser: true, // Set to true if you want to allow browser usage
        apiVersion: "2024-08-01-preview"
      });
      const chatCompletion = await openai.chat.completions.create({
        messages: promptTemplate,
        model: 'gpt-4o',
      });
      console.log("chat completin is", chatCompletion);
      if ((chatCompletion.choices[0].message.content).toLowerCase().includes("ingredient:") || (chatCompletion.choices[0].message.content).toLowerCase().includes("ingredients:") || (chatCompletion.choices[0].message.content).toLowerCase().includes("cookwares:") || (chatCompletion.choices[0].message.content).toLowerCase().includes("cookware:")) {
        this.setState({
          loading: true
        })
        this.setState({ userMessages: [...this.state.userMessages, chatCompletion.choices[0].message] })
        this.setState({ userMessages: [...this.state.userMessages, { role: "assistant", content: "Quality check started... I'll update the ingredients and instructions if this <b>change request passes</b> our food quality test." }] })


        let newrecipe = this.props.recipe;
        let animatevalue;
        // newrecipe.ingredients.forEach(n => {
        //   if (n.ingredientOptions[0].id == oldValue) {
        //     const pattern = /new:\s*([^,]+)/;

        //     // Using match to find the pattern in the string
        //     const match = ingredientString.match(pattern);

        //     if (match) {
        //       const newValue = match[1].trim(); // Remove any leading/trailing whitespaces
        //       console.log(newValue);
        //       n.ingredientOptions[0].id = newValue;
        //       n.ingredientOptions[0].name = newValue;
        //       animatevalue = newValue
        //     } else {
        //       console.log("No match found.");
        //     }


        //   }
        //   console.log("new", newrecipe)
        // })
        promptTemplate.push(...this.state.promptMessages, {
          role: 'assistant', content: `As a professional sous chef, generate a precise JSON response based on previously suggested modifications. Follow these key guidelines:

          Recipe Modification Rules:
          1. Update only confirmed changes from previous suggestions
          2. Maintain recipe integrity and culinary standards
          3. Use real, verified ingredients only
          4. Ensure all modifications reflect in steps and instructions
          5. Update title and description to match ingredient changes
          6. Adjust cookware and steps in parallel
          
          JSON Structure Requirements:
          {
            "title": "Recipe Name",
            "description": "Professional culinary description with accurate ingredient references",
            "ingredients": [
              {
                "text": "Precise measurement and ingredient",
                "updated": boolean
              }
            ],
            "steps": [
              {
                "text": "Action description",
                "instruction": "Detailed instructions method",
                "duration": "time_in_seconds - Required and important",
              }
            ],
            "cookware": [
              {
                "originalText": "Equipment name",
                "step": number,
                "quantity": number,
                "size": "size_specification",
                "notes": "Usage notes",
                "requiredModes": [],
                "id": "unique_identifier",
                "name": "Display name",
                "type": "cookware|appliance"
              }
            ]
          }
          
          Quality Standards:
          - Exact measurements and quantities
          - Professional culinary terminology
          - Logical step sequences
          - Proper equipment specifications
          - Accurate timing durations
          - Complete ingredient details
                 ` })
        const azureApiKeyOld = config.AZURE_KEY;
        const openaiNew = new AzureOpenAI({
          endpoint: config.AZURE_ENDPOINT,
          apiKey: azureApiKeyOld,
          dangerouslyAllowBrowser: true, // Set to true if you want to allow browser usage
          apiVersion: "2024-08-01-preview"
        });
        const chatCompletionnew = await openaiNew.chat.completions.create({
          messages: promptTemplate,
          model: 'gpt-4o',
          response_format: { type: 'json_object' }
        });
        if((chatCompletionnew.choices[0].finish_reason).toLowerCase() !== "stop") {
          this.setState({
            loading: false
          })
          this.setState({ userMessages: [...this.state.userMessages, { role: "assistant", content: "Unable to update the recipe" }] })
          return;
        }
        let newrecipeJson = JSON.parse(chatCompletionnew.choices[0].message.content);
        let newStepsArray = newrecipeJson.steps
        console.log("newStepsArray", newStepsArray)
        newStepsArray = newStepsArray.map((step, index) => {
          return {
            "number": Number(index) + 1,
            "text": `${step.text}`,
            "annotatedText": `${step.instruction}`,
            "duration": `${step.duration}`,
            "summary": `${step.text}`,
            "widgets": []
          }
        })
        newrecipe.steps = newStepsArray;
        newrecipe.title = newrecipeJson.title;
        newrecipe.description = newrecipeJson.description;
        newrecipe.cookware = newrecipeJson.cookware;

        const transformedResponse = newrecipeJson.ingredients?.map((item, index) => {
          return {
            order: index + 1,
            originalText: "model",
            ingredientOptions: [
              {
                ingredient: "",
                quantity: [],
                measurement: {
                  unit: "unknown"
                },
                id: item.text,
                name: item.text,
                updated: item.updated
              }
            ]
          };
        });
        newrecipe.ingredients = transformedResponse;
        console.log(transformedResponse, "transformedResponse", newrecipe)
        // const checkScore = await fetch(`${config.AI_BASE_URL}/backend/validate_recipe`, {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json"
        //   },
        //   body: JSON.stringify({
        //     "key": config.AI_API_KEY,
        //     "original_recipe": {
        //       "title": this.props.recipe.title,
        //       "description": this.props.recipe.description,
        //       "steps_list": this.props.recipe.steps,
        //     },
        //     "personalized_recipe": {
        //       "steps_list": newStepsArray,
        //       "title": newrecipe.title,
        //       "description": newrecipe.description
        //     },
        //     "preferences": {
        //     }
        //   })
        // })
        // const checkScoreJson = await checkScore.json()
        // console.log("checkScoreJson", checkScoreJson)
        this.props.updateNewReceipe(newrecipe, animatevalue)
        this.setState({
          loading: false
        })

        this.setState({
          userMessages: [...this.state.userMessages, { role: "assistant", content: "Done!" }
          ]
        })



      } else {
        this.setState({ userMessages: [...this.state.userMessages, chatCompletion.choices[0].message] }) //simple value
      }
      this.setState({ message: '' });
      this.setState({
        loading: false
      })
    } catch (error) {
      console.log('Error processing your request', error);
    }


  }
  render() {
    const messages = [
      { text: "Hey there! How are you doing?", time: "10:00 AM", sender: "user" },
      { text: "I'm good, thank you! How about you?", time: "10:05 AM", sender: "bot" }
    ];
    this.changeRecipe = this.changeRecipe.bind(this)

    function setViewportHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    setViewportHeight();

    window.addEventListener('resize', setViewportHeight);
    return (
      <div style={{
        position: "sticky",
        top: "8rem",
        // boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
        overflow: "scroll"
        // backgroundColor: "white"
      }}>
        <div className="flex flex-col rounded-3xl lg:!h-[80vh]" style={{
          height: `calc(var(--vh, 1vh) * 100)`
        }}>
          <div className="flex justify-between p-4 rounded-t-2xl text-white font-bold text-center text-xl" style={{
            background: "linear-gradient(to right,  #0c5212 0%,  #3ba958 35%)"
          }}>
            <span>Chat with our Sous Chef</span>
            <span className='lg:hidden' onClick={this.props.onClose}><X /></span>
          </div>
          <div className="flex-grow overflow-y-scroll lg:mb-0 w-screen lg:w-full bg-white" style={{
            // height: "70vh",
          }}>
            <div className="flex flex-col space-y-2 p-4">
              {this.state.userMessages.map((message, index) => (
                <div key={index} className={`flex items-center ${message.role === 'user' ? 'self-end' : 'self-start'}`}>

                  <div className={`rounded-[10px] px-4 py-2 ${message.role === 'user' ? 'bg-green-600 text-white rounded-br-none' : 'bg-gray-300 rounded-bl-none'}`}
                    style={{
                      boxShadow: "0 2px 2px rgba(0,0,0,0.2)",
                      wordWrap: "break-word",
                      overflowWrap: "break-word"
                    }}>

                    <p style={{ wordWrap: "break-word", overflowWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: message.content }}>
                    </p>
                  </div>
                </div>
              ))}
              {this.state.loading && (
                <div className="mt-6">
                  <div className="typing">
                    <span className="circle scaling"></span>
                    <span className="circle  scaling"></span>
                    <span className="circle scaling"></span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center p-3 bg-white" style={{
            boxShadow: "0 -2px 2px rgba(0,0,0,0.1)",
            // position: "fixed",
            bottom: "0%",
            maxHeight: "60px", // Max height before scrolling
            overflowY: "auto"
          }}>
            {this.state.loading ? <div className="flex-grow outline-none opacity-55 text-gray-400" style={{
              border: 'none',
              resize: 'none',
              outline: 'none',
              width: '100%', // Ensure full width within the container
              minHeight: '50px', // Minimum height to accommodate text
              padding: '10px 20px', // Adjust padding to vertically center the text
              borderRadius: '25px', // Rounded corners
              // boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.1)' // Subtle shadow inside the textarea
            }}>Processing...</div> : <textarea
              type="text"
              id='input-field'
              value={this.state.input}
              placeholder='Type your message...'
              wrap='soft'
              style={{
                border: 'none',
                resize: 'none',
                outline: 'none',
                width: '100%', // Ensure full width within the container
                minHeight: '50px', // Minimum height to accommodate text
                padding: '10px 20px', // Adjust padding to vertically center the text
                borderRadius: '25px', // Rounded corners
                // boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.1)' // Subtle shadow inside the textarea
              }}

              onChange={(e) => this.setState({ message: e.target.value, input: e.target.value })}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();  // Prevent the default action
                  if (e.target.value == "") return
                  this.changeRecipe(e);
                }
              }}
              className="flex-grow outline-none"
            />}
            <button
              disabled={this.state.loading || this.state.input == ""}
              className="ml-4 bg-green-600 text-white rounded-full p-2"
              style={{
                width: "35px",
                height: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
              onClick={this.changeRecipe}
            >
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" className="w-6 h-6">
                <path d="M5 12h14M12 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  }
}


export default ConsumerChat;
