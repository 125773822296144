import React, { Component } from 'react';
import { Link } from "react-router-dom";

import '../../css/account.css';
import UserService from '../../services/user';
import { Snackbar, Checkbox, FormControlLabel } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import config from "../../utils/config"
import AuthService from '../../services/auth';

class RegisterUser extends Component {

    redirectTimer;
    ACCOUNT_LOGIN_REDIRECT_DELAY = 2000;

    constructor(props) {
        super(props);

        document.title = `Ladle - Create Account`;
        const urlParams = new URLSearchParams(window.location.search);
        const inviteCode = urlParams.get('invite-code') ? urlParams.get('invite-code').trim() : undefined;

        this.state = {
            email: undefined,
            password: undefined,
            userName: undefined,
            registrationError: undefined,
            accountCreated: undefined,
            termsAccepted: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTermsAcceptance = this.handleTermsAcceptance.bind(this);
    }

    componentWillUnmount() {
        clearTimeout(this.redirectTimer);
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value,
            registrationError: undefined,
        });
    }

    handleTermsAcceptance(event) {
        this.setState({
            termsAccepted: event.target.checked,
            registrationError: undefined,
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        let { email, password, userName, termsAccepted } = this.state;

        if (!termsAccepted) {
            this.setState({ registrationError: 'You must accept the Terms of Service and Privacy Policy to continue' });
            return;
        }

        const userObj = { email, password, name: userName };

        try {
            const userRes = await UserService.create(userObj);
            console.log({ msg: 'handleSubmit > userRes: ', userRes });

            if (userRes.user) {
                console.log('Account created successfully!', userRes.user);

                if (sessionStorage.getItem("from_chris_source") === "true") {
                    const contestObj = {
                        user_email: userRes.user.email,
                        creator_name: "chris"
                    };
                    const contestRes = await fetch(`${config.API_SERVER_URL}/api/contest`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(contestObj),
                    });
                    const contestData = await contestRes.json();
                    console.log('Contest created successfully!', contestData);
                }

                // Automatically log the user in after account creation
                const loginRes = await AuthService.login(email, password);
                const { setLoginStateFunc } = this.props;
                const defaultUserType = "consumer";

                if (loginRes.user) {
                    console.log("Logged in automatically after account creation");
                    const { user } = loginRes;
                    console.log({ msg: 'handleSubmit > loginRes: ', loginRes });
                    let userType = (user.roles && user.roles[0]) ? user.roles[0] : defaultUserType;
                    setLoginStateFunc({ userType, userToken: user.token });
                    window.location.href = "/consumer/recipes";
                } else {
                    this.setState({ registrationError: 'Account created but login failed. Please log in manually.' });
                }
            } else if (userRes.error) {
                this.setState({ accountCreated: false, user: undefined, registrationError: userRes.error });
            } else {
                this.setState({ accountCreated: false, user: undefined, registrationError: 'Failed to create account' });
            }
        } catch (error) {
            console.log({ msg: 'handleSubmit > error: ', error });
            this.setState({ accountCreated: false, user: undefined, registrationError: 'An error occurred while creating the account' });
        }
    }


    redirectOnAccountCreation = () => {
        console.group({ msg: 'redirectOnAccountCreation', props: this.props })
        this.props.navigate('/');
    }

    render() {
        const { registrationError, accountCreated, termsAccepted } = this.state;
        const registrationErrorHtml = registrationError ? <div className="account-error">{registrationError}</div> : <></>;
        const accountCreatedHtml = accountCreated ? <div className="account-success">Account Created!<br /><span className='account-success-redirecting'>Redirecting to Login.</span></div> : <></>;

        return (
            <>
                <div className="account-app register">
                    <div className="account-app-background"></div>
                    <section className="section-content">
                        <div className="account-tile register">
                            <div className="account-tile-header">
                                <img className="logo" src="/ladle_logo_highres.png" alt="Ladle Logo" />
                                <span className="instruction-text text-center">Please fill in the details below to create an account</span>
                            </div>

                            {registrationErrorHtml}
                            {accountCreatedHtml}

                            <form onSubmit={this.handleSubmit}>

                                <div className="form-group">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        name="userName"
                                        required
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        required
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        name="password"
                                        required
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="form-group terms-checkbox">
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        marginBottom: '10px'
                                    }}>
                                        <Checkbox
                                            checked={termsAccepted}
                                            onChange={this.handleTermsAcceptance}
                                            sx={{
                                                color: '#4CAF50',
                                                '&.Mui-checked': {
                                                    color: '#4CAF50',
                                                },
                                                padding: '0 10px 0 0',
                                                marginTop: '-2px'
                                            }}
                                        />
                                        <span style={{
                                            fontSize: '14px',
                                            lineHeight: '1.5',
                                            display: 'inline-block',
                                            textAlign: 'left'
                                        }}>
                                            By signing up, I accept the{' '}
                                            <a
                                                href="/terms-of-service"
                                                target="_blank"
                                                className="text-green-700 underline"
                                            >
                                                Terms of Service
                                            </a>
                                            {' '}and{' '}
                                            <a
                                                href="/privacy-policy"
                                                target="_blank"
                                                className="text-green-700 underline"
                                            >
                                                Privacy Policy
                                            </a>
                                        </span>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className='green'
                                    disabled={!termsAccepted}
                                >
                                    Create
                                    <FontAwesomeIcon icon={faPaperPlane} style={{ marginLeft: '8px' }} />
                                </button>
                                <div className="help-text text-center">
                                    Need help or want to learn more? <Link to="mailto:info@ladle.cooking">Send us a note</Link>
                                </div>
                            </form>
                        </div>
                    </section>
                    <footer>
                        Copyright  {new Date().getFullYear()} Ladle Cooking, Inc.
                    </footer>
                </div>

                <Snackbar
                    open={accountCreated}
                    autoHideDuration={2000}
                    message='Redirecting to login...'
                />
            </>
        );
    }
}

export default RegisterUser;
