import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import config from '../../utils/config';

const PublicSearchIngredients = () => {
  const [ingredients, setIngredients] = useState('');
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Extract ingredients from URL query parameters
    const searchParams = new URLSearchParams(location.search);
    const ingredientsParam = searchParams.get('ingredients');
    
    if (ingredientsParam) {
      setIngredients(ingredientsParam);
      searchRecipesByIngredients(ingredientsParam);
    }
  }, [location.search]);

  const searchRecipesByIngredients = async (ingredientsToSearch) => {
    if (!ingredientsToSearch.trim()) return;
    
    setLoading(true);
    try {
      // Replace with your actual API endpoint for searching recipes by ingredients
      const response = await fetch(`${config.API_SERVER_URL}/api/v2/recipes/search-by-ingredients?ingredients=${encodeURIComponent(ingredientsToSearch)}`);
    console.log('Response:', response);
      const data = await response.json();
      setRecipes(data.recipes || []);
    } catch (error) {
      console.error('Error searching recipes by ingredients:', error);
      setRecipes([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    searchRecipesByIngredients(ingredients);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>What's in My Kitchen | Ladle</title>
        <meta name="description" content="Find recipes based on ingredients you already have in your kitchen." />
      </Helmet>
      <h1 className="text-3xl font-bold mb-6 text-center">What's in My Kitchen</h1>
      
      <div className="max-w-2xl mx-auto mb-8">
        <form onSubmit={handleSubmit} className="flex flex-col md:flex-row gap-4">
          <input
            type="text"
            value={ingredients}
            onChange={(e) => setIngredients(e.target.value)}
            placeholder="Enter ingredients separated by commas (e.g., chicken, rice, onions)"
            className="flex-grow px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-500"
          />
          <button
            type="submit"
            disabled={!ingredients.trim() || loading}
            className="bg-[#0e4621] hover:bg-[#3e8e41] text-white px-6 py-2 rounded disabled:opacity-50"
          >
            {loading ? 'Searching...' : 'Search'}
          </button>
        </form>
        <p className="text-sm text-gray-600 mt-2">
          Enter ingredients you have, separated by commas, to find recipes you can make.
        </p>
      </div>

      {loading ? (
        <div className="flex justify-center my-12">
          <div className="loading-icon">
            <img src="/loading.svg" alt="Loading" />
          </div>
        </div>
      ) : (
        <div>
          {recipes.length > 0 ? (
            <div>
              <h2 className="text-2xl font-semibold mb-4">Recipes you can make:</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {recipes.map((recipe) => (
                  <div key={recipe.id} className="bg-white rounded-lg shadow-md overflow-hidden">
                    <Link to={`/recipes/${recipe.slug}`}>
                      <img 
                        src={recipe.image || '/placeholder-recipe.jpg'} 
                        alt={recipe.title} 
                        className="w-full h-48 object-cover"
                      />
                      <div className="p-4">
                        <h3 className="text-xl font-semibold mb-2">{recipe.title}</h3>
                        <div className="flex items-center text-sm text-gray-600 mb-2">
                          <span>{recipe.cookTime} min</span>
                          <span className="mx-2">•</span>
                          <span>{recipe.cuisine}</span>
                        </div>
                        <p className="text-gray-700 line-clamp-2">{recipe.description}</p>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ingredients.trim() && (
              <div className="text-center my-12">
                <p className="text-xl">No recipes found with those ingredients.</p>
                <p className="mt-2">Try adding more ingredients or using more common ones.</p>
              </div>
            )
          )}

          {!ingredients.trim() && !loading && (
            <div className="text-center my-12">
              <p className="text-xl">Enter ingredients to find recipes you can make!</p>
              <div className="mt-6">
                <h3 className="font-semibold mb-2">Popular ingredient combinations to try:</h3>
                <div className="flex flex-wrap justify-center gap-2 mt-3">
                  <button 
                    onClick={() => {
                      setIngredients('chicken, rice, garlic');
                      searchRecipesByIngredients('chicken, rice, garlic');
                    }}
                    className="bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded"
                  >
                    chicken, rice, garlic
                  </button>
                  <button 
                    onClick={() => {
                      setIngredients('pasta, tomato, cheese');
                      searchRecipesByIngredients('pasta, tomato, cheese');
                    }}
                    className="bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded"
                  >
                    pasta, tomato, cheese
                  </button>
                  <button 
                    onClick={() => {
                      setIngredients('eggs, flour, milk');
                      searchRecipesByIngredients('eggs, flour, milk');
                    }}
                    className="bg-gray-200 hover:bg-gray-300 px-3 py-1 rounded"
                  >
                    eggs, flour, milk
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <div className="mt-12 text-center">
        <p className="text-gray-600">
          Want more personalized recipe recommendations?{' '}
          <Link to="/login" className="text-[#0e4621] hover:underline font-medium">
            Sign in
          </Link>{' '}
          to save your favorite recipes and get tailored suggestions.
        </p>
      </div>
    </div>
  );
};

export default PublicSearchIngredients;
