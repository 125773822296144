import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { X, Search, Plus } from "lucide-react";
import config from "../../utils/config";
import RecipeGrid from "../../components/RecipeGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown, faTemperatureEmpty, faCarrot, faEgg, faPepperHot, faLeaf, faDrumstickBite } from "@fortawesome/free-solid-svg-icons";
import { Player } from '@lottiefiles/react-lottie-player';
import loader from '../../loader/main_loading_animation.json';

const IngredientSearch = (props) => {
  const [ingredients, setIngredients] = useState([]);
  const [currentIngredient, setCurrentIngredient] = useState("");
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(12);
  const [totalRecipes, setTotalRecipes] = useState(0);
  const [hasSearched, setHasSearched] = useState(false);
  const [showAllSuggestions, setShowAllSuggestions] = useState(false);

  useEffect(() => {
    if (!hasSearched) {
      loadTrendingRecipes();
    }
  }, []);

  const loadTrendingRecipes = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${config.API_SERVER_URL}/api/v3/trending-recipes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          page,
          perPage
        }),
      });
      const data = await response.json();
      setRecipes(data.recipes || []);
      setTotalRecipes(data.total || 0);
    } catch (error) {
      console.error("Error loading trending recipes:", error);
    } finally {
      setLoading(false);
    }
  };

  const searchRecipes = async () => {
    if (ingredients.length === 0) {
      loadTrendingRecipes();
      return;
    }

    setLoading(true);
    setHasSearched(true);
    
    try {
      const response = await fetch(`${config.API_SERVER_URL}/api/v3/search-by-ingredients`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ingredients,
          page,
          perPage
        }),
      });
      
      const data = await response.json();
      if (data.recipes && data.recipes.length > 0) {
        setRecipes(data.recipes);
        setTotalRecipes(data.total || 0);
      } else {
        await loadTrendingRecipes();
      }
    } catch (error) {
      console.error("Error searching recipes:", error);
      await loadTrendingRecipes();
    } finally {
      setLoading(false);
    }
  };

  const addIngredient = () => {
    if (currentIngredient.trim() !== "") {
      // Split by comma and process each ingredient
      const ingredientList = currentIngredient.split(',');
      
      let newIngredients = [...ingredients];
      
      ingredientList.forEach(item => {
        const trimmedItem = item.trim().toLowerCase();
        if (trimmedItem !== "" && !newIngredients.includes(trimmedItem)) {
          newIngredients.push(trimmedItem);
        }
      });
      
      setIngredients(newIngredients);
      setCurrentIngredient("");
    }
  };
  

  const removeIngredient = (index) => {
    const updatedIngredients = [...ingredients];
    updatedIngredients.splice(index, 1);
    setIngredients(updatedIngredients);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addIngredient();
    }
  };

  const handleSearch = () => {
    setPage(1);
    searchRecipes();
  };

  const changePage = (newPage) => {
    setPage(newPage);
    window.scrollTo(0, 0);
    searchRecipes();
  };

  // Map icons to some ingredients for visual appeal
  const ingredientIcons = {
    "chicken": faDrumstickBite,
    "tomato": faPepperHot,
    "onion": faCarrot,
    "spinach": faLeaf,
    "egg": faEgg,
  };

  const suggestionIngredients = [
    "chicken", "tomato", "onion", "garlic", "pasta", 
    "beef", "rice", "potato", "carrot", "bell pepper",
    "cheese", "egg", "spinach", "broccoli", "mushroom", 
    "basil", "lemon", "olive oil", "butter", "milk"
  ];

  const visibleSuggestions = showAllSuggestions 
    ? suggestionIngredients 
    : suggestionIngredients.slice(0, 10);

  const handleTagClick = (ingredient) => {
    if (!ingredients.includes(ingredient)) {
      setIngredients([...ingredients, ingredient]);
    }
  };

  // Helper function to get random pastel color for ingredient buttons
  const getIngredientColor = (ingredient) => {
    const colors = [
      'bg-[#e8f5ec] text-[#0e4621]', // Green
      'bg-[#f0f5ff] text-[#1e40af]', // Blue
      'bg-[#fff4e6] text-[#c05621]', // Orange
      'bg-[#faf5ff] text-[#6b46c1]', // Purple
      'bg-[#f0ffff] text-[#047481]'  // Teal
    ];
    
    // Use string hashing to assign consistent colors to ingredients
    let hash = 0;
    for (let i = 0; i < ingredient.length; i++) {
      hash = ingredient.charCodeAt(i) + ((hash << 5) - hash);
    }
    
    return colors[Math.abs(hash) % colors.length];
  };

  return (
    <>
      {loading ? (
        <div style={{ minWidth: '100px', textAlign: 'center', verticalAlign: 'middle', alignItems: 'center', justifyContent: 'center', height: '400px', display: 'flex', flexDirection: 'column' }}>
          <Player
            src={loader}
            className="player w-[40%]"
            loop
            autoplay
          />
        </div>
      ) : (
        <div className="content-section mx-auto mt-5">
          <div className="px-4 md:px-0">
            <h1 className="text-2xl mb-3 font-bold text-[#0e4621] mt-5">What's in your kitchen?</h1>
            <p className="text-gray-600 mb-5">Search for recipes using ingredients you already have</p>
            
            {/* Redesigned Search Card */}
            <div className="bg-white rounded-lg shadow-sm border border-gray-100 overflow-hidden mb-8">
              {/* Input Section - Streamlined */}
              <div className="p-4">
                <div className="relative flex mb-4">
                  <input
                    type="text"
                    value={currentIngredient}
                    onChange={(e) => setCurrentIngredient(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter an ingredient (e.g., chicken, tomato)"
                    className="w-full px-4 py-2.5 border-2 border-gray-200 rounded-md focus:outline-none focus:border-[#0e4621] transition-colors pr-12"
                  />
                  <button
                    onClick={addIngredient}
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[#0e4621] hover:text-[#0a3419] p-2 transition duration-300 flex items-center"
                  >
                    <Plus size={18} />
                  </button>
                </div>
                
                {/* Ingredients Tags - Compact and Styled */}
                {ingredients.length > 0 && (
                  <div className="mb-4">
                    <div className="flex flex-wrap gap-2">
                      {ingredients.map((ingredient, index) => (
                        <div key={index} className="bg-[#0e4621] text-white px-3 py-1.5 rounded-full flex items-center text-sm shadow-sm">
                          <span>{ingredient}</span>
                          <button 
                            onClick={() => removeIngredient(index)}
                            className="ml-2 text-white hover:text-red-200 focus:outline-none"
                          >
                            <X size={14} />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              
              {/* Popular Ingredients Section - More Visually Appealing */}
              <div className="bg-[#f7f9f8] border-t border-b border-gray-100 py-3 px-4">
                <div className="flex justify-between items-center mb-2">
                  <p className="text-[#0e4621] font-medium">Popular ingredients</p>
                  <button 
                    onClick={() => setShowAllSuggestions(!showAllSuggestions)}
                    className="text-[#0e4621] text-sm flex items-center hover:underline"
                  >
                    {showAllSuggestions ? "Show less" : "Show more"}
                    <FontAwesomeIcon 
                      className="ml-1" 
                      icon={showAllSuggestions ? faChevronUp : faChevronDown} 
                    />
                  </button>
                </div>
                <div className="flex flex-wrap gap-2">
                  {visibleSuggestions.map((ingredient) => {
                    const colorClass = getIngredientColor(ingredient);
                    return (
                      <button
                        key={ingredient}
                        onClick={() => handleTagClick(ingredient)}
                        className={`px-3 py-1.5 rounded-full text-sm font-medium transition-all hover:shadow-md ${
                          ingredients.includes(ingredient)
                            ? "bg-gray-200 text-gray-500 cursor-not-allowed opacity-60"
                            : `${colorClass} hover:opacity-90`
                        } flex items-center`}
                        disabled={ingredients.includes(ingredient)}
                      >
                        {ingredientIcons[ingredient] && (
                          <FontAwesomeIcon 
                            icon={ingredientIcons[ingredient]} 
                            className="mr-1.5" 
                          />
                        )}
                        {ingredient}
                      </button>
                    );
                  })}
                </div>
              </div>
              
              {/* Search Button - Compact Footer */}
              <div className="px-4 py-3">
                <button
                  onClick={handleSearch}
                  className="w-full bg-[#0e4621] hover:bg-[#0a3419] text-white py-2.5 rounded-md transition duration-300 flex items-center justify-center shadow-sm hover:shadow"
                >
                  <Search size={16} className="mr-2" />
                  <span className="font-medium">Find Recipes</span>
                </button>
              </div>
            </div>
            
            {/* Results Section */}
            <div className="flex flex-col md:flex-row md:justify-between md:items-center md:mt-6">
              <div>
                <h1 className="text-2xl mb-3 font-bold text-[#0e4621]">
                  {hasSearched && ingredients.length > 0 
                    ? `Recipes with ${ingredients.join(', ')}`
                    : "Trending Recipes"}
                </h1>
              </div>
            </div>
            
            {recipes.length > 0 ? (
              <div className="p-4 md:p-0">
                <RecipeGrid 
                  recipes={recipes} 
                  type='consumer' 
                  user={props.user} 
                  tenant={props.tenant} 
                  handleTagClick={handleTagClick} 
                />
              </div>
            ) : (
              <div className="text-center p-12">
                <p className="text-center p-3">
                  <FontAwesomeIcon icon={faTemperatureEmpty} style={{ paddingRight: '12px' }} />
                  No recipes found
                </p>
              </div>
            )}
            
            {/* Pagination */}
            {totalRecipes > perPage && (
              <div className="flex justify-center mt-8 mb-12">
                <div className="flex space-x-2">
                  <button
                    onClick={() => changePage(page - 1)}
                    disabled={page === 1}
                    className={`px-4 py-2 rounded-md transition ${
                      page === 1 
                        ? "bg-gray-100 text-gray-400" 
                        : "bg-[#e8f5ec] text-[#0e4621] hover:bg-[#d0ebda]"
                    }`}
                  >
                    Previous
                  </button>
                  
                  <div className="flex items-center px-4">
                    <span className="text-gray-700">
                      Page {page} of {Math.ceil(totalRecipes / perPage)}
                    </span>
                  </div>
                  
                  <button
                    onClick={() => changePage(page + 1)}
                    disabled={page >= Math.ceil(totalRecipes / perPage)}
                    className={`px-4 py-2 rounded-md transition ${
                      page >= Math.ceil(totalRecipes / perPage) 
                        ? "bg-gray-100 text-gray-400" 
                        : "bg-[#e8f5ec] text-[#0e4621] hover:bg-[#d0ebda]"
                    }`}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default IngredientSearch;
