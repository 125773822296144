import React from 'react';
import { Link } from 'react-router-dom';

const SignupPrompt = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-md mx-4 animate-fade-in">
        <h2 className="text-2xl font-bold text-[#0e4621] mb-4">
          Unlock the Full Ladle Experience!
        </h2>
        <div className="space-y-4 text-gray-600">
          <p className="flex items-center">
            <span className="mr-2">✨</span>
            Access personalized recipe recommendations
          </p>
          <p className="flex items-center">
            <span className="mr-2">🤖</span>
            Chat with AI to customize any recipe
          </p>
          <p className="flex items-center">
            <span className="mr-2">❤️</span>
            Save your favorite recipes
          </p>
          <p className="flex items-center">
            <span className="mr-2">🔍</span>
            Advanced search filters
          </p>
        </div>
        <div className="mt-8 flex gap-4">
          <Link to="/register">
            <button className="bg-[#0e4621] text-white px-6 py-2 rounded-lg hover:bg-[#0a3419] transition-colors">
              Sign Up Free
            </button>
          </Link>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            Maybe Later
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignupPrompt;
