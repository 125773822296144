import React, { useState } from 'react';
import MenuBar from './MenubarChris';
import config from "../../utils/config.js";

const CookingClassSignup = () => {
    const [howHeard, setHowHeard] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        howHeard: 'Social media',
        otherReason: '',
        cookingSkillLevel: 'Beginner',
        dietaryRestrictions: '',
        specificDiet: '',
        termsAccepted: false
    });

    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        
        if (!formData.termsAccepted) {
            setError('You must accept the Terms of Service and Privacy Policy to continue');
            return;
        }
        
        setIsSubmitting(true);

        try {
            const response = await fetch(`${config.API_SERVER_URL}/api/register-class`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error);
            }

            setSuccess(true);
            setFormData({
                name: '',
                email: '',
                howHeard: 'Social media',
                otherReason: '',
                cookingSkillLevel: 'Beginner',
                dietaryRestrictions: '',
                specificDiet: '',
                termsAccepted: false
            });
        } catch (err) {
            setError(err.message);
        } finally {
            setIsSubmitting(false);
        }
    };
    
    const handleTermsAcceptance = (e) => {
        setFormData({
            ...formData,
            termsAccepted: e.target.checked
        });
    };
    
    return (
        <>
            { !localStorage.getItem('userToken') ? <MenuBar /> :
                null}
            <div className="min-h-screen bg-white py-12 px-4 sm:px-6 lg:px-8">
                <div className='text-center md:flex md:justify-center'>
                    <h1 className="text-3xl md:text-4xl md:w-[45%] font-bold mb-4  px-4 md:px-0 bg-gradient-to-r from-green-600 via-green-400 to-green-600 animate-gradient bg-[length:200%_auto] text-transparent bg-clip-text"
                        style={{ animation: 'shine 3s linear infinite' }}>
                        Join Our Exclusive Virtual Cooking Class with CaribbeanPot
                    </h1>                </div>

                <div className="max-w-7xl mx-auto mt-10 grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="flex items-start justify-center px-4 md:px-0">
                        <img
                            src="/cooking-class/1.png"
                            alt="Caribbean Cooking Class"
                            className="rounded-[30px] shadow-xl w-full md:w-[70%] object-contain"
                        />
                    </div>

                    <div className="flex flex-col justify-start">
                        <div className="mb-8">
                            <p className="text-lg text-black lg:mt-4 md:mt-0">Join us for a special virtual cooking class with <span className='font-bold'>Chris De La Rosa of CaribbeanPot</span> — the master of bold Caribbean flavors. Learn how to cook his  <span className='font-bold'>famous Curry Shrimp recipe</span> with us!</p>

                            <div className="space-y-2 mt-5">
                                <p className="text-black"><span className="font-bold">Date:</span> March 12, 2025</p>
                                <p className="text-black"><span className="font-bold">Time:</span> 6:30–7:30pm ET (3:30–4:30pm PT | 5:30–6:30 CT)</p>
                                <p className="text-black"><span className="font-bold">Location:</span> Online (link provided after registration)</p>
                                <p className="text-black"><span className="font-bold">Cost:</span> FREE</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="max-w-3xl mx-auto mt-12">
                    <form onSubmit={handleSubmit} className="space-y-6 bg-gray-50 p-8 rounded-lg">
                        {error && (
                            <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-4">
                                <p className="text-red-700">{error}</p>
                            </div>
                        )}

                        {success && (
                            <div className="bg-green-50 border-l-4 border-green-400 p-4 mb-4">
                                <p className="text-green-700">Registration successful! Happy cooking!</p>
                            </div>                        
                        )}

                        <h2 className="text-2xl font-bold text-center text-gray-900 mb-8">Register Now</h2>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Name</label>
                            <input
                                type="text"
                                value={formData.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                                required
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Email</label>
                            <input
                                type="email"
                                value={formData.email}
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                                required
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">How did you hear about this?</label>
                            <select
                                value={formData.howHeard}
                                onChange={(e) => setFormData({ ...formData, howHeard: e.target.value })}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                            >
                                <option>Social media</option>
                                <option>Email</option>
                                <option>Other</option>
                            </select>
                            {formData.howHeard === 'Other' && (
                                <textarea
                                    value={formData.otherReason}
                                    onChange={(e) => setFormData({ ...formData, otherReason: e.target.value })}
                                    className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                                    rows="3"
                                    placeholder="Please specify how you heard about us"
                                    required
                                />
                            )}
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Cooking skill level</label>
                            <select
                                value={formData.cookingSkillLevel}
                                onChange={(e) => setFormData({ ...formData, cookingSkillLevel: e.target.value })}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                            >
                                <option>Beginner</option>
                                <option>Intermediate</option>
                                <option>Advanced</option>
                            </select>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Dietary restrictions or allergies</label>
                            <textarea
                                value={formData.dietaryRestrictions}
                                onChange={(e) => setFormData({ ...formData, dietaryRestrictions: e.target.value })}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                                rows="3"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Are you vegetarian, vegan, or follow a specific diet?</label>
                            <textarea
                                value={formData.specificDiet}
                                onChange={(e) => setFormData({ ...formData, specificDiet: e.target.value })}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                                rows="3"
                            />
                        </div>
                        
                        <div className="terms-checkbox">
                            <div style={{ 
                                display: 'flex',
                                alignItems: 'flex-start',
                                marginBottom: '10px'
                            }}>
                                <input
                                    type="checkbox"
                                    checked={formData.termsAccepted}
                                    onChange={handleTermsAcceptance}
                                    style={{
                                        marginRight: '10px',
                                        marginTop: '4px',
                                        cursor: 'pointer',
                                        accentColor: '#4CAF50'
                                    }}
                                    required
                                />
                                <span style={{ 
                                    fontSize: '14px',
                                    lineHeight: '1.5',
                                    display: 'inline-block',
                                    textAlign: 'left'
                                }}>
                                    By clicking the "Register for Class" button, you agree to our{' '}
                                    <a
                                        href="/terms-of-service"
                                        target="_blank"
                                        className="text-green-600 hover:text-green-800 underline"
                                    >
                                        Terms of Service
                                    </a>
                                    {' '}and{' '}
                                    <a
                                        href="/privacy-policy"
                                        target="_blank" 
                                        className="text-green-600 hover:text-green-800 underline"
                                    >
                                        Privacy Policy
                                    </a>
                                </span>
                            </div>
                        </div>

                        <div className='flex lg:block'>
                            <button
                                type="submit"
                                disabled={isSubmitting || !formData.termsAccepted}
                                className={`mx-auto px-8 py-3 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
                                ${isSubmitting || !formData.termsAccepted ? 'bg-green-400 cursor-not-allowed' : 'bg-green-600 hover:bg-green-700'} 
                                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
                            >
                                {isSubmitting ? 'Registering...' : 'Register for Class'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default CookingClassSignup;
