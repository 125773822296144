// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recipe-grid {
  /* display: grid;
  row-gap: 30px;
  column-gap: 30px; */
}

.recipe-grid a {
  text-decoration: none;
  font-size: inherit;
  color: inherit;
}`, "",{"version":3,"sources":["webpack://./src/css/RecipeGrid.css"],"names":[],"mappings":"AAAA;EACE;;qBAEmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".recipe-grid {\n  /* display: grid;\n  row-gap: 30px;\n  column-gap: 30px; */\n}\n\n.recipe-grid a {\n  text-decoration: none;\n  font-size: inherit;\n  color: inherit;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
