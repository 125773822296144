const getPrettyDuration = (seconds) => {
    if (seconds < 60) {
        return seconds + (seconds === 1 ? " second" : " seconds");
      } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const minutesText = minutes + (minutes === 1 ? " minute" : " minutes");
        const secondsText = remainingSeconds + (remainingSeconds === 1 ? " second" : " seconds");
        return minutesText + (remainingSeconds > 0 ? " " + secondsText : "");
      } else if (seconds < 86400) {
        const hours = Math.floor(seconds / 3600);
        const remainingMinutes = Math.floor((seconds % 3600) / 60);
        const hoursText = hours + (hours === 1 ? " hour" : " hours");
        const minutesText = remainingMinutes + (remainingMinutes === 1 ? " minute" : " minutes");
        return hoursText + (remainingMinutes > 0 ? " and " + minutesText : "");
      } else {
        const days = Math.floor(seconds / 86400);
        const remainingHours = Math.floor((seconds % 86400) / 3600);
        const daysText = days + (days === 1 ? " day" : " days");
        const hoursText = remainingHours + (remainingHours === 1 ? " hour" : " hours");
        return daysText + (remainingHours > 0 ? " and " + hoursText : "");
      }
};

export default getPrettyDuration;