import React from 'react';
import MenuBar from './MenubarChris';

const TermsOfService = () => {
  // Check if user is logged in to determine if MenuBar should be shown
  const isLoggedIn = localStorage.getItem('userToken');

  return (
    <>
      {!isLoggedIn && <MenuBar />}
      <div className="min-h-screen bg-white py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          {/* Header */}
          <div className="text-center mb-12">
            <h1 className="text-3xl md:text-4xl font-bold mb-4 text-[#0e4621]">
              Terms of Service
            </h1>
            <p className="text-gray-600">Last Updated: March 2025</p>
          </div>

          {/* Section 1: Business Information */}
          <div className="mb-10">
            <h2 className="text-2xl font-bold mb-4 text-[#0e4621] border-b-2 border-[#0e4621] pb-2">
              1. Business Information
            </h2>
            <div className="space-y-4 text-gray-700">
              <p><strong>Name of company:</strong> Ladle Cooking Inc. ("Ladle")</p>
              <p><strong>Purpose of the service:</strong> Helping people cook what they want to eat by personalizing recipes based on their preferences - diets, ingredients, appliances, languages and regions. Ladle offers a free trial and a paid subscription plan that users can purchase.</p>
            </div>
          </div>

          {/* Section 2: Acceptance of Terms */}
          <div className="mb-10">
            <h2 className="text-2xl font-bold mb-4 text-[#0e4621] border-b-2 border-[#0e4621] pb-2">
              2. Acceptance of Terms
            </h2>
            <div className="space-y-4 text-gray-700">
              <p>By accessing, using or creating an account on app.ladle.cooking ("Service"), you ("Subscriber", "you") confirm that you have read, understood and agreed to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use the Service. By continuing to use the Service you are indicating consent to both the Ladle's Terms of Service and <a href='/privacy-policy' className='text-[#0e4621] underline'> Privacy Policy</a>.</p>
              
              <h3 className="text-xl font-semibold mt-6 mb-2 text-[#0e4621]">Methods of Acceptance</h3>
              <p>Subscriber accepts these Terms when you:</p>
              <ul className="list-disc pl-6 space-y-1">
                <li>Click "I Agree," "Accept," or a similar button when prompted during account registration or when using certain features.</li>
                <li>Continue using the Service after updates to these Terms have been posted.</li>
                <li>Access or interact with the Service in any way.</li>
              </ul>
              
              <h3 className="text-xl font-semibold mt-6 mb-2 text-[#0e4621]">Eligibility</h3>
              <p>Subscriber may only use the Service if you:</p>
              <ul className="list-disc pl-6 space-y-1">
                <li>Are at least 18 years old (or meet the minimum age required by your jurisdiction to enter into an enforceable contract).</li>
                <li>Have the legal capacity to enter into a binding agreement.</li>
                <li>Are not prohibited from using the Service under applicable laws.</li>
              </ul>
              
              <h3 className="text-xl font-semibold mt-6 mb-2 text-[#0e4621]">Updates to These Terms</h3>
              <p>We may modify these Terms at any time. If we make significant changes, we will notify subscriber via email, notifications within the Service, or a banner on our website. Subscriber's continued use of the Service after such updates constitutes acceptance of the revised Terms.</p>
            </div>
          </div>

          {/* Section 3: Subscription Terms */}
          <div className="mb-10">
            <h2 className="text-2xl font-bold mb-4 text-[#0e4621] border-b-2 border-[#0e4621] pb-2">
              3. Subscription Terms
            </h2>
            <div className="space-y-4 text-gray-700">
              <h3 className="text-xl font-semibold mt-2 mb-2 text-[#0e4621]">Subscription Plans:</h3>
              <ul className="list-disc pl-6 space-y-1">
                <li><strong>Free Trial:</strong> You are allowed to use three (3) recipes or recipe customizations ("Personalizations"). After which continued use of the Service will only be enabled after signing up for the paid plan.</li>
                <li><strong>Paid Plan:</strong> Up to 50 recipes/recipe Personalizations monthly at a cost of $4.99 per month or $55 annually. YOUR PAID PLAN WILL AUTOMATICALLY RENEW AT THE END OF EACH PAYMENT CYCLE (EITHER MONTHLY OR YEARLY, DEPENDING ON THE PLAN YOU PURCHASE) UNTIL YOU CANCEL YOUR SUBSCRIPTION.</li>
              </ul>
              
              <h3 className="text-xl font-semibold mt-6 mb-2 text-[#0e4621]">Billing & Payment:</h3>
              <p>Payment process on Ladle is enabled via credit cards automatically billed monthly or annually as selected. Subscriptions will automatically renew unless you choose to cancel. By providing a credit card or other payment method for the purchase of our Service, you represent and warrant that you are authorized to use the designated payment method and that you authorize our third-party payment processor to charge your payment method for the total amount of your paid plan. If the payment method cannot be verified, is invalid, or is otherwise not acceptable, your paid plan may be suspended or cancelled. You must resolve any problem we (or our third-party payment processor) encounter in order to proceed with your paid plan. You acknowledge that the amount billed may vary due to promotional offers, changes in the Service, or changes in applicable taxes or other charges, and you authorize us (or our third-party payment processor) to charge your payment method for the corresponding amount.</p>
              
              <h3 className="text-xl font-semibold mt-6 mb-2 text-[#0e4621]">Cancellations and Refunds:</h3>
              <p>Subscriber can cancel your subscription at any time by mailing info@ladle.cooking. Your cancellation will take effect at the end of your current billing period and you will retain access to the Service until that time. We do not offer refunds or prorated billing for unused portions of a subscription.</p>
            </div>
          </div>

          {/* Section 4: Account Responsibilities */}
          <div className="mb-10">
            <h2 className="text-2xl font-bold mb-4 text-[#0e4621] border-b-2 border-[#0e4621] pb-2">
              4. Account Responsibilities
            </h2>
            <div className="space-y-4 text-gray-700">
              <p><strong>Account Creation:</strong> Subscriber must provide accurate information during registration.</p>
              <p><strong>User Responsibilities:</strong> Subscriber is responsible for keeping your account details secure and must notify us of any unauthorized use.</p>
            </div>
          </div>

          {/* Section 5: Cooking Class Terms */}
          <div className="mb-10">
            <h2 className="text-2xl font-bold mb-4 text-[#0e4621] border-b-2 border-[#0e4621] pb-2">
              5. Cooking Class Terms
            </h2>
            <div className="space-y-4 text-gray-700">
              <p>From time to time Ladle will offer virtual cooking classes. These may or may not be included in the paid subscription. Premium/masterclasses may come with additional costs which will be specified at the time of registration.</p>
              
              <h3 className="text-xl font-semibold mt-6 mb-2 text-[#0e4621]">Class Access:</h3>
              <p>Information to access the classes (live, recorded, etc.) will be provided via the Service or email or social media. Any prerequisites for preparation (e.g., certain kitchen tools or ingredients) will be available in the recipe on the Service.</p>
              
              <h3 className="text-xl font-semibold mt-6 mb-2 text-[#0e4621]">Liability Waiver:</h3>
              <p>Subscriber will participate in classes at your own risk. You are responsible for accidents or injuries during class or while cooking. YOU HEREBY RELEASE AND HOLD LADLE (AND LADLE'S OFFICERS, DIRECTORS, EMPLOYEES, PARENTS, SUBSIDIARIES, OTHER AFFILIATES, SUCCESSORS, ASSIGNS, AGENTS, REPRESENTATIVES, AND INDEPENDENT CONTRACTORS) HARMLESS FROM ANY INJURY, CLAIM OR CONTROVERSY THAT MAY ARISE FROM YOUR PARTICIPATION IN ANY CLASSES.</p>
            </div>
          </div>

          {/* Section 6: Content Ownership */}
          <div className="mb-10">
            <h2 className="text-2xl font-bold mb-4 text-[#0e4621] border-b-2 border-[#0e4621] pb-2">
              6. Content Ownership
            </h2>
            <div className="space-y-4 text-gray-700">
              <h3 className="text-xl font-semibold mt-2 mb-2 text-[#0e4621]">Intellectual Property:</h3>
              <p>As between you and Ladle, Ladle owns the recipes and videos on the Service, as well as all other content made available on the Service (all such content except Your Content, the "Ladle Content") and you can't reproduce, distribute, modify, or otherwise use Ladle Content without Ladle's permission.</p>
              
              <h3 className="text-xl font-semibold mt-6 mb-2 text-[#0e4621]">Your Content:</h3>
              <p>If subscriber submits content – video, recipes, photos or feedback (collectively, "Your Content"), you hereby grant Ladle a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free license, with the right to sublicense, to use, view, access, copy, adapt, modify, distribute, publicly display, transmit, and otherwise exploit Your Content, in any media, in order to provide the Service to you. You acknowledge and agree that you are solely responsible for Your Content. Accordingly, you represent and warrant that (i) you have all rights, licenses, consents and releases that are necessary to grant to Ladle the license above, and (ii) our use and other users' use of Your Content as permitted by these Terms will not infringe the intellectual property rights of any third party. If Your Content violates these Terms in any way, we reserve the right to remove Your Content from the Service.</p>
            </div>
          </div>

          {/* Section 7: Privacy Policy */}
          <div className="mb-10">
            <h2 className="text-2xl font-bold mb-4 text-[#0e4621] border-b-2 border-[#0e4621] pb-2">
              7. Privacy Policy
            </h2>
            <div className="space-y-4 text-gray-700">
              <p>Please refer to <a href="/privacy-policy" className="text-[#0e4621] underline">Ladle Privacy Policy</a>. It governs how user personal data is collected, used, and stored.</p>
            </div>
          </div>

          {/* Section 8: Suspension and Termination */}
          <div className="mb-10">
            <h2 className="text-2xl font-bold mb-4 text-[#0e4621] border-b-2 border-[#0e4621] pb-2">
              8. Suspension and Termination
            </h2>
            <div className="space-y-4 text-gray-700">
              <p>Ladle may immediately and without notice suspend or terminate these Terms and disable your access to the Service for any or no reason.</p>
            </div>
          </div>

          {/* Section 9: Disclaimer of Warranties */}
          <div className="mb-10">
            <h2 className="text-2xl font-bold mb-4 text-[#0e4621] border-b-2 border-[#0e4621] pb-2">
              9. Disclaimer of Warranties
            </h2>
            <div className="space-y-4 text-gray-700">
              <p>We are not medical professionals and cannot guarantee any ingredients or substitutions will be safe for your consumption. Please consult your medical professional for allergy guidance.</p>
              
              <div className="mt-4 p-4 bg-gray-100 border-l-4 border-[#0e4621]">
                <p>YOU HEREBY ACKNOWLEDGE THAT YOU ARE USING THE SERVICE AT YOUR OWN RISK. THE SERVICE AND LADLE CONTENT ARE PROVIDED "AS IS," AND LADLE AND ITS AFFILIATES HEREBY DISCLAIM ANY AND ALL WARRANTIES, EXPRESS AND IMPLIED, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF ACCURACY, RELIABILITY, MERCHANTABILITY, NON-INFRINGEMENT, FITNESS FOR A PARTICULAR PURPOSE, AND ANY OTHER WARRANTY, CONDITION, GUARANTEE OR REPRESENTATION, WHETHER ORAL, IN WRITING OR IN ELECTRONIC FORM. LADLE AND ITS AFFILIATES DO NOT REPRESENT OR WARRANT THAT ACCESS TO THE SERVICE WILL BE UNINTERRUPTED OR THAT THERE WILL BE NO FAILURES, ERRORS OR OMISSIONS OR LOSS OF TRANSMITTED INFORMATION, OR THAT NO VIRUSES WILL BE TRANSMITTED THROUGH THE SERVICES.</p>
              </div>
              
              <p>Because some states do not permit disclaimer of implied warranties, you may have additional rights under your local laws.</p>
            </div>
          </div>

          {/* Section 10: Limitation of Liability */}
          <div className="mb-10">
            <h2 className="text-2xl font-bold mb-4 text-[#0e4621] border-b-2 border-[#0e4621] pb-2">
              10. Limitation of Liability
            </h2>
            <div className="space-y-4 text-gray-700">
              <div className="mt-4 p-4 bg-gray-100 border-l-4 border-[#0e4621]">
              <p>TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL LADLE BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) $20 OR (II) THE AMOUNTS PAID BY YOU TO LADLE IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM, OR (C) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.</p>
              </div>
            </div>
          </div>

          {/* Section 11: Dispute Resolution */}
          <div className="mb-10">
            <h2 className="text-2xl font-bold mb-4 text-[#0e4621] border-b-2 border-[#0e4621] pb-2">
              11. Dispute Resolution
            </h2>
            <div className="space-y-4 text-gray-700">
              <p>Subscriber agrees that all matters arising out of or relating to use of this service are governed by, and construed in accordance with, the laws of California, without giving effect to any of its conflict of laws provisions thereof. Subscriber further agrees that any dispute relating to using this service shall be handled through arbitration. The arbitration shall be held in accordance with the Streamlined Arbitration Rules and Procedures of Judicial Arbitration and Mediation Services, Inc. ("JAMS") then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes, who shall be selected from the appropriate list of JAMS arbitrators in accordance with such Rules. The arbitration shall be held in the US county of your most recent physical address or, if there is no such US county, in San Francisco, California. Judgment upon the award rendered by such arbitrator may be entered in any court of competent jurisdiction. Any arbitration under these Terms will take place on an individual basis: class arbitrations and class actions are not permitted.</p>
              
              <div className="mt-4 p-4 bg-gray-100 border-l-4 border-[#0e4621]">
                <p>YOU UNDERSTAND AND AGREE THAT BY ENTERING INTO THESE TERMS, YOU AND LADLE ARE EACH WAIVING THE RIGHT TO TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
