// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userApplianceTable {
    /* min-width: 1240px; */
}

.userApplianceTable tr:last-child td {
    border-bottom: none;
}

.userApplianceTable tr {
}

.userApplianceTable tr td {
    border-bottom: 1px solid #ccc;
}

/***/

.addApplianceDialog {
    background-color: rgba(0, 0, 0, 65%);
}

.addApplianceTable tr:last-child td {
    border-bottom: none;
}

.addApplianceTableContainer {
    border-radius: 24px;
}

.addApplianceDialog .MuiDialogContent-root {
    padding: 24px 24px 24px 24px;
}

.addApplianceDialog .MuiPaper-root {
    border: 1px solid #ddd;
    border-radius: 24px;
    box-shadow: none;
    background-color: #fafafa;
}

.addApplianceTable {
    background-color: #ffffff;
    border-radius: 24px;
    min-width: 540px;
}

.addApplianceDialogTitle.MuiTypography-root {
    padding: 24px 0px 16px 28px;
}

.addApplianceDialogActions {
    margin-right: 24px;
    margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/css/ConsumerManageAppliances.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;AACA;;AAEA;IACI,6BAA6B;AACjC;;AAEA,IAAI;;AAEJ;IACI,oCAAoC;AACxC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".userApplianceTable {\n    /* min-width: 1240px; */\n}\n\n.userApplianceTable tr:last-child td {\n    border-bottom: none;\n}\n\n.userApplianceTable tr {\n}\n\n.userApplianceTable tr td {\n    border-bottom: 1px solid #ccc;\n}\n\n/***/\n\n.addApplianceDialog {\n    background-color: rgba(0, 0, 0, 65%);\n}\n\n.addApplianceTable tr:last-child td {\n    border-bottom: none;\n}\n\n.addApplianceTableContainer {\n    border-radius: 24px;\n}\n\n.addApplianceDialog .MuiDialogContent-root {\n    padding: 24px 24px 24px 24px;\n}\n\n.addApplianceDialog .MuiPaper-root {\n    border: 1px solid #ddd;\n    border-radius: 24px;\n    box-shadow: none;\n    background-color: #fafafa;\n}\n\n.addApplianceTable {\n    background-color: #ffffff;\n    border-radius: 24px;\n    min-width: 540px;\n}\n\n.addApplianceDialogTitle.MuiTypography-root {\n    padding: 24px 0px 16px 28px;\n}\n\n.addApplianceDialogActions {\n    margin-right: 24px;\n    margin-bottom: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
