const NODE_ENV = process.env.REACT_APP_NODE_ENV || "production";
const DEFAULT_LOG_LEVEL = NODE_ENV === "production" ? "info" : "debug";

// remember all vars in .env must start with REACT_APP_

const config = {
  NODE_ENV,
  LOG_LEVEL: process.env.REACT_APP_LOG_LEVEL || DEFAULT_LOG_LEVEL,
  API_SERVER_URL: process.env.REACT_APP_API_SERVER_URL,
  AUTH_SERVER_URL: process.env.REACT_APP_AUTH_SERVER_URL,
  AUTH_REGISTRATION_ENDPOINT: process.env.REACT_APP_AUTH_REGISTRATION_ENDPOINT,
  AUTH_LOGIN_ENDPOINT: process.env.REACT_APP_AUTH_LOGIN_ENDPOINT,
  AUTH_GUEST_ENDPOINT: process.env.REACT_APP_AUTH_GUEST_ENDPOINT,
  DEFAULT_CREATOR_ID: process.env.REACT_APP_DEFAULT_CREATOR_ID,
  DEFAULT_BRAND_ID: process.env.REACT_APP_DEFAULT_BRAND_ID,
  ENABLE_MY_KITCHEN: process.env.REACT_APP_ENABLE_MY_KITCHEN === "true",
  ENABLE_MY_PROFILE: process.env.REACT_APP_ENABLE_MY_PROFILE === "true",
  ENABLE_ADD_RECIPE: process.env.REACT_APP_ENABLE_ADD_RECIPE === "true",
  DEMO_MODE: process.env.REACT_APP_DEMO_MODE === "true",
  SUPPORTED_APPLIANCE_TYPES: process.env.REACT_APP_SUPPORTED_APPLIANCE_TYPES ? process.env.REACT_APP_SUPPORTED_APPLIANCE_TYPES.split(',') : [],
  OPENAI_KEY: process.env.REACT_APP_OPENAI_KEY,
  AI_BASE_URL: process.env.REACT_APP_AI_BASE_URL,
  AI_API_KEY:  process.env.REACT_APP_AI_API_KEY,
  AZURE_KEY: process.env.REACT_APP_AZURE_KEY,
  AZURE_ENDPOINT: process.env.REACT_APP_AZURE_ENDPOINT,
  AZURE_API_KEY: process.env.REACT_APP_AZURE_REAL_API_KEY,
};

export default config;