// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.consumer-profile .tile {
    min-width: 1024px;
}

  .consumer-profile h2 {
    color: #333;
    margin-bottom: 24px;
  }

  .consumer-profile label {
    margin-top: 4px;
    display: block;
  }

  .consumer-profile input, select, textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .consumer-profile button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .consumer-profile button:hover {
    background-color: #45a049;
  }`, "",{"version":3,"sources":["webpack://./src/css/consumerprofile.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;AACrB;;EAEE;IACE,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["\n.consumer-profile .tile {\n    min-width: 1024px;\n}\n\n  .consumer-profile h2 {\n    color: #333;\n    margin-bottom: 24px;\n  }\n\n  .consumer-profile label {\n    margin-top: 4px;\n    display: block;\n  }\n\n  .consumer-profile input, select, textarea {\n    width: 100%;\n    padding: 8px;\n    margin-bottom: 8px;\n    box-sizing: border-box;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n\n  .consumer-profile button {\n    background-color: #4CAF50;\n    color: white;\n    padding: 10px 20px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n\n  .consumer-profile button:hover {\n    background-color: #45a049;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
