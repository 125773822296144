import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faClose, faChevronRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import config from "../utils/config";
import MenuDropdown from "./MenuDropdown";

// Define the dropdown options (copied from MenuBar.js)
const cuisineOptions = [
  { id: "mexican", label: "Mexican" },
  { id: "german", label: "German" },
  { id: "chinese", label: "Chinese" },
  { id: "american", label: "American" },
  { id: "italian", label: "Italian" },
  { id: "japanese", label: "Japanese" },
  { id: "spanish", label: "Spanish" },
  { id: "korean", label: "Korean" },
  { id: "moroccan", label: "Moroccan" },
];

const ingredientOptions = [
  { id: "beef", label: "Beef" },
  { id: "pork", label: "Pork" },
  { id: "chicken", label: "Chicken" },
  { id: "tofu", label: "Tofu" },
  { id: "eggs", label: "Eggs" },
  { id: "seafood", label: "Seafood" },
  { id: "vegetables", label: "Vegetables" },
  { id: "pasta", label: "Pasta" },
];

const mealOptions = [
  { id: "quick", label: "30 Minute Meals" },
  { id: "breakfast", label: "Breakfast/Brunch" },
  { id: "lunch", label: "Lunch" },
  { id: "dinner", label: "Dinner" },
  { id: "desserts", label: "Desserts" },
  { id: "healthy", label: "Healthy" },
];

const dietOptions = [
  { id: "vegan", label: "Vegan" },
  { id: "vegetarian", label: "Vegetarian" },
  { id: "keto", label: "Keto" },
  { id: "paleo", label: "Paleo" },
  { id: "dairy free", label: "Dairy-Free" },
  { id: "gluten free", label: "Gluten-Free" },
  { id: "kosher", label: "Kosher" },
];

const creatorOptions = [
  { id: "Chris De La Rosa", label: "Chris De La Rosa" }
];

const PublicMenuBar = () => {
  const [searchBarVisible, setSearchBarVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchMode, setSearchMode] = useState("full"); // full or kitchen
  const [currentIngredient, setCurrentIngredient] = useState("");
  const [ingredientsList, setIngredientsList] = useState([]);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const searchBarRef = useRef();
  const navigate = useNavigate();

  const handleSearchOnClick = () => {
    const addMarginTopAndAnimationClass = document.getElementById("mainId");
    setSearchBarVisible(!searchBarVisible);
    
    if (!searchBarVisible) {
      if (window.innerWidth < 1024) {
        addMarginTopAndAnimationClass.style.marginTop = "11rem";
      } else {
        addMarginTopAndAnimationClass.style.marginTop = "20rem";
      }
    } else {
      if (window.innerWidth < 1024) {
        addMarginTopAndAnimationClass.style.marginTop = "5rem";
      } else {
        addMarginTopAndAnimationClass.style.marginTop = "7rem";
      }
    }
  };

  const isUrlValid = (userInput) => {
    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return res != null;
  };

  const handleSearchOnSubmit = async (e) => {
    const addMarginTopAndAnimation = document.getElementById("mainId");
    if (window.innerWidth < 1024) {
      addMarginTopAndAnimation.style.marginTop = "5rem";
    } else {
      addMarginTopAndAnimation.style.marginTop = "7rem";
    }

    let query;
    if (typeof e == "object") {
      e.preventDefault();
      query = e.target.value;
    } else {
      query = `${e}`;
    }

    if (!isUrlValid(query)) {
      const url = query ? `/recipes?search=${query}` : `/recipes`;
      setSearchBarVisible(false);
      setSearchQuery("");
      navigate(url);
    }
  };

  const handleToggleSearchMode = () => {
    setSearchMode(prevMode => prevMode === "full" ? "kitchen" : "full");
    setSearchQuery("");
    setIngredientsList([]);
    setCurrentIngredient("");
  };

  const handleRemoveIngredient = (index) => {
    const updatedIngredientsList = [...ingredientsList];
    updatedIngredientsList.splice(index, 1);
    setIngredientsList(updatedIngredientsList);
  };

  const handleSearchByIngredients = () => {
    const addMarginTopAndAnimation = document.getElementById("mainId");
    if (window.innerWidth < 1024) {
      addMarginTopAndAnimation.style.marginTop = "5rem";
    } else {
      addMarginTopAndAnimation.style.marginTop = "7rem";
    }

    setSearchBarVisible(false);

    // Construct the query parameter from currentIngredient
    const queryParams = currentIngredient && currentIngredient.length !== 0
      ? `?ingredients=${currentIngredient}`
      : "";

    // Navigate to the public version of the kitchen search page
    navigate(`/recipes/whats-in-my-kitchen${queryParams}`);
  };

  const handleMenuOptionClick = (menuId, optionId) => {
    setSearchBarVisible(false);
    
    if (menuId === "cuisines")
      navigate(`/recipes/cuisines/${optionId}`);
    if (menuId === "diets")
      navigate(`/recipes/diets/${optionId}`);
    if (menuId === "meals")
      navigate(`/recipes/meals/${optionId}`);
    if (menuId === "ingredients")
      navigate(`/recipes/ingredients/${optionId}`);
    if (menuId === "creators")
      navigate(`/creator/chris-de-la-rosa`);
  };

  const toggleMenu = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
    setSearchBarVisible(false);
    setOpenSubmenu(null);
  };

  const toggleSubmenu = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  const handleMenuClick = () => {
    setSearchBarVisible(false);
  };

  // Close mobile menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      const mobileMenu = document.getElementById('mobile-menu');
      const menuButton = document.getElementById('mobile-menu-button');
      
      if (isMobileMenuVisible && mobileMenu && !mobileMenu.contains(event.target) && 
          menuButton && !menuButton.contains(event.target)) {
        setIsMobileMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuVisible]);

  return (
    <header className={`${searchBarVisible ? "search-active" : null} fixed left-1/2 transform -translate-x-1/2 bg-[#0e4621] px-4 xl:px-12 py-2 z-10 w-full`}>
      <div className="flex justify-between items-center">
        <Link to="/">
          <img className="logo" src="/Ladle_app_highres_rounded_square.png" alt="Logo" />
        </Link>

        {/* Desktop Navigation Menu */}
        <div className="hidden lg:flex items-center gap-4 flex-grow justify-center">
          <MenuDropdown
            id="cuisines"
            items={cuisineOptions}
            onClick={handleMenuClick}
            handleOptionClick={handleMenuOptionClick}
          >
            Cuisines
          </MenuDropdown>
          
          <span className="menuDivider hidden lg:block"></span>
          
          <MenuDropdown
            id="diets"
            items={dietOptions}
            onClick={handleMenuClick}
            handleOptionClick={handleMenuOptionClick}
          >
            Diets
          </MenuDropdown>
          
          <span className="menuDivider hidden lg:block"></span>
          
          <MenuDropdown
            id="meals"
            items={mealOptions}
            onClick={handleMenuClick}
            handleOptionClick={handleMenuOptionClick}
          >
            Meals
          </MenuDropdown>
          
          <span className="menuDivider hidden lg:block"></span>
          
          <MenuDropdown
            id="ingredients"
            items={ingredientOptions}
            onClick={handleMenuClick}
            handleOptionClick={handleMenuOptionClick}
          >
            Ingredients
          </MenuDropdown>
          
          <span className="menuDivider hidden lg:block"></span>
          
          <MenuDropdown
            id="creators"
            items={creatorOptions}
            onClick={handleMenuClick}
            handleOptionClick={handleMenuOptionClick}
          >
            Creators
          </MenuDropdown>
        
        </div>

        <div className="flex items-center gap-6">
          <FontAwesomeIcon 
            className="text-white text-xl cursor-pointer" 
            icon={faSearch} 
            onClick={handleSearchOnClick}
          />

          <div className="flex items-center gap-4">
            <Link to="/login">
              <button className="bg-white text-[#0e4621] px-4 py-2 rounded">Sign In</button>
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button
            id="mobile-menu-button"
            className="flex flex-col items-center justify-center w-10 h-8 space-y-2 rounded focus:outline-none lg:hidden"
            onClick={toggleMenu}
          >
            <span className="block w-6 h-0.5 bg-white"></span>
            <span className="block w-6 h-0.5 bg-white"></span>
            <span className="block w-6 h-0.5 bg-white"></span>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuVisible && (
        <div id="mobile-menu" className="lg:hidden bg-[#0e4621] text-white mt-4 py-2 px-4 rounded-md">
          <div className="flex flex-col space-y-2">
            {/* Cuisines */}
            <div className="border-b border-gray-600 py-2">
              <button 
                className="flex items-center justify-between w-full text-left py-2"
                onClick={() => toggleSubmenu(0)}
              >
                <span>Cuisines</span>
                <FontAwesomeIcon icon={openSubmenu === 0 ? faChevronDown : faChevronRight} />
              </button>
              
              {openSubmenu === 0 && (
                <div className="pl-4 mt-2 space-y-2">
                  {cuisineOptions.map((option) => (
                    <button 
                      key={option.id} 
                      className="block w-full text-left py-2 hover:text-gray-300"
                      onClick={() => {
                        handleMenuOptionClick("cuisines", option.id);
                        toggleMenu();
                      }}
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
              )}
            </div>
            
            {/* Diets */}
            <div className="border-b border-gray-600 py-2">
              <button 
                className="flex items-center justify-between w-full text-left py-2"
                onClick={() => toggleSubmenu(1)}
              >
                <span>Diets</span>
                <FontAwesomeIcon icon={openSubmenu === 1 ? faChevronDown : faChevronRight} />
              </button>
              
              {openSubmenu === 1 && (
                <div className="pl-4 mt-2 space-y-2">
                  {dietOptions.map((option) => (
                    <button 
                      key={option.id} 
                      className="block w-full text-left py-2 hover:text-gray-300"
                      onClick={() => {
                        handleMenuOptionClick("diets", option.id);
                        toggleMenu();
                      }}
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
              )}
            </div>
            
            {/* Meals */}
            <div className="border-b border-gray-600 py-2">
              <button 
                className="flex items-center justify-between w-full text-left py-2"
                onClick={() => toggleSubmenu(2)}
              >
                <span>Meals</span>
                <FontAwesomeIcon icon={openSubmenu === 2 ? faChevronDown : faChevronRight} />
              </button>
              
              {openSubmenu === 2 && (
                <div className="pl-4 mt-2 space-y-2">
                  {mealOptions.map((option) => (
                    <button 
                      key={option.id} 
                      className="block w-full text-left py-2 hover:text-gray-300"
                      onClick={() => {
                        handleMenuOptionClick("meals", option.id);
                        toggleMenu();
                      }}
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
              )}
            </div>
            
            {/* Ingredients */}
            <div className="border-b border-gray-600 py-2">
              <button 
                className="flex items-center justify-between w-full text-left py-2"
                onClick={() => toggleSubmenu(3)}
              >
                <span>Ingredients</span>
                <FontAwesomeIcon icon={openSubmenu === 3 ? faChevronDown : faChevronRight} />
              </button>
              
              {openSubmenu === 3 && (
                <div className="pl-4 mt-2 space-y-2">
                  {ingredientOptions.map((option) => (
                    <button 
                      key={option.id} 
                      className="block w-full text-left py-2 hover:text-gray-300"
                      onClick={() => {
                        handleMenuOptionClick("ingredients", option.id);
                        toggleMenu();
                      }}
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
              )}
            </div>
            
            {/* Creators */}
            <div className="border-b border-gray-600 py-2">
              <button 
                className="flex items-center justify-between w-full text-left py-2"
                onClick={() => toggleSubmenu(4)}
              >
                <span>Creators</span>
                <FontAwesomeIcon icon={openSubmenu === 4 ? faChevronDown : faChevronRight} />
              </button>
              
              {openSubmenu === 4 && (
                <div className="pl-4 mt-2 space-y-2">
                  {creatorOptions.map((option) => (
                    <button 
                      key={option.id} 
                      className="block w-full text-left py-2 hover:text-gray-300"
                      onClick={() => {
                        handleMenuOptionClick("creators", option.id);
                        toggleMenu();
                      }}
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
              )}
            </div>
            
            {/* What's in My Kitchen */}

          </div>
        </div>
      )}

      {searchBarVisible && (
        <div ref={searchBarRef} className="absolute w-full left-0 top-full bg-gray-100 search-bar-container p-3">
          {showError && (
            <div className="text-center text-xl font-bold mt-3 text-red-700">
              URL is invalid
            </div>
          )}

          <form className="search-bar" onSubmit={(e) => e.preventDefault()}>
            <div className="input-container w-full flex flex-col sm:flex-row justify-center items-center">
              {loading ? (
                <div className="loading-icon">
                  <img alt="" src="/loading.svg" />
                </div>
              ) : (
                <div className="flex flex-col lg:flex-row justify-center items-center w-full lg:w-auto">
                  <div className="relative w-full lg:w-auto">
                    <textarea
                      style={{
                        height: "50px",
                        width: "650px",
                        wordBreak: "break-word",
                        resize: "none",
                      }}
                      autoFocus
                      className="hidden lg:block px-5"
                      placeholder={
                        searchMode === "full"
                          ? "Search anything, Search for specific ingredients"
                          : "Enter ingredients separated by commas (e.g., chicken, beans)"
                      }
                      value={
                        searchMode === "full"
                          ? searchQuery
                          : currentIngredient
                      }
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          if (searchMode === "full") {
                            handleSearchOnSubmit(e.target.value);
                          } else {
                            handleSearchByIngredients();
                          }
                        }
                      }}
                      onChange={(e) => {
                        if (searchMode === "full") {
                          setSearchQuery(e.target.value);
                        } else {
                          setCurrentIngredient(e.target.value);
                        }
                      }}
                    />
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        maxWidth: "600px",
                        maxHeight: "300px",
                        display: window.innerWidth < 1024 ? "" : "none",
                      }}
                      autoFocus
                      className="px-3 py-2 rounded-sm border border-gray-300 focus:ring-2 focus:ring-green-500 transition w-full"
                      placeholder={
                        searchMode === "full"
                          ? "Search anything, search for specific ingredients"
                          : "Enter ingredients separated by commas (e.g., chicken, beans)"
                      }
                      value={
                        searchMode === "full"
                          ? searchQuery
                          : currentIngredient
                      }
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          if (searchMode === "full") {
                            handleSearchOnSubmit(e.target.value);
                          } else {
                            handleSearchByIngredients();
                          }
                        }
                      }}
                      onChange={(e) => {
                        if (searchMode === "full") {
                          setSearchQuery(e.target.value);
                        } else {
                          setCurrentIngredient(e.target.value);
                        }
                      }}
                    />
                    <button
                      onClick={() => window.location.href = '/search'}
                      className="mt-2 w-full lg:hidden bg-[#0e4621] hover:bg-[#3e8e41] text-white px-4 py-2 rounded"
                    >
                      Search using what is in your kitchen
                    </button>
                  </div>

                  <div className="hidden lg:block">
                    <button
                      disabled={
                        searchMode === "full"
                          ? searchQuery === ""
                          : currentIngredient === ""
                      }
                      onClick={() => {
                        if (searchMode === "full") {
                          handleSearchOnSubmit(searchQuery);
                        } else {
                          handleSearchByIngredients();
                        }
                      }}
                      className="lg:ml-3 h-10 bg-[#0e4621] hover:bg-[#3e8e41] text-white px-4 rounded"
                    >
                      Search
                    </button>
                  </div>
                </div>
              )}
            </div>

            {/* Ingredients List for Kitchen Search Mode */}
            {searchMode === "kitchen" && (
              <div className="ingredients-list mt-4 text-center">
                {ingredientsList.length > 0 && (
                  <div className="flex flex-wrap justify-center">
                    {ingredientsList.map((ingredient, index) => (
                      <span
                        key={index}
                        className="bg-gray-300 px-2 py-1 mx-1 my-1 text-black rounded-[4px] flex items-center"
                      >
                        {ingredient}
                        <FontAwesomeIcon
                          className="ml-1 cursor-pointer"
                          icon={faClose}
                          onClick={() => handleRemoveIngredient(index)}
                        />
                      </span>
                    ))}
                  </div>
                )}
              </div>
            )}
          </form>

          {/* Toggle between search modes */}
          {/* <div className="text-center mt-4 mb-4">
            <button
              type="button"
              className={`px-4 py-2 rounded-full ${
                searchMode === "full"
                  ? "bg-green-600 text-white"
                  : "bg-gray-300 text-black"
              }`}
              onClick={handleToggleSearchMode}
            >
              {searchMode === "full"
                ? "Search using What's in Your Kitchen"
                : "Search by Keywords"}
            </button>
          </div> */}

          {/* Keyword Suggestions for Full Search */}
          {searchMode === "full" && (
            <div className="hidden lg:block text-center bg-gray-100 px-4">
              <a href="/search" className="inline-block mt-2 mb-4">
                <button className="px-4 py-2 bg-green-600 text-white rounded-full">
                  Search using What's in Your Kitchen
                </button>
              </a>
              <br/>
              <span style={{ opacity: "0.5" }}>Try these keywords</span>
              <div className="flex flex-col justify-center gap-2">
                <div className="mt-3">
                  <span
                    className="bg-gray-300 px-2 py-1 text-black rounded-[4px] cursor-pointer"
                    onClick={() => {
                      setSearchQuery("Chicken ramen noodles");
                    }}
                  >
                    <img
                      alt=""
                      src="/try-prompt.png"
                      className="inline h-4 mr-1 mb-1"
                    />
                    Chicken ramen noodles
                  </span>
                  <span
                    className="bg-gray-300 px-2 py-1 ml-4 text-black rounded-[4px] cursor-pointer"
                    onClick={() => {
                      setSearchQuery("Raspberry crumble bars");
                    }}
                  >
                    <img
                      alt=""
                      src="/try-prompt.png"
                      className="inline h-4 mr-1 mb-1"
                    />
                    Raspberry crumble bars
                  </span>
                </div>
                <div className="mt-3">
                  <span
                    className="bg-gray-300 px-2 py-1 text-black rounded-[4px] cursor-pointer"
                    onClick={() => {
                      setSearchQuery("Chicken pot pie");
                    }}
                  >
                    <img
                      alt=""
                      src="/try-prompt.png"
                      className="inline h-4 mr-1 mb-1"
                    />
                    Chicken pot pie
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </header>
  );
};

export default PublicMenuBar;

