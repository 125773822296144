import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import ConsumerRecipeCardPublic from '../../components/ConsumerNotLoggedInrecipe';
import ConsumerRecipeCardMbPublic from '../../components/ConsumerRecipeCardPublic';
import RecipeService from '../../services/recipe';
import SmartRecipeService from '../../services/smartrecipe';
class ConsumerViewRecipeConsumerPagePublic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recipe_id: this.props.params.recipe_id,
      isMobile: true,
      recipe: null
    };
  }

  async componentDidMount() {
   
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.setState({ isMobile: true })
    } else {
      
      this.setState({ isMobile: false })
    }

    try {
      let recipe =  await SmartRecipeService.getNewRecipebasedOnSlug(window.location.pathname.split('/recipes/')[1]);
      localStorage.setItem("recipe_id", recipe._id)
      if(localStorage.getItem("userToken")) {
        window.location.href = `/consumer/recipes/${recipe._id}/view`
      } 
      this.setState({ recipe: recipe });
      
    } catch (err) {
      console.log("error of reso is", err)

      console.log('Error fetching recipe:', err);
    }
  }

  render() {
    const { recipe } = this.state;
    return (
      <>
        {recipe && (
          <Helmet prioritizeSeoTags>
            <title>{`${recipe.title} - Easy Homemade Recipe | Ladle`}</title>
            <meta name="description" content={`Make perfect ${recipe.title} with step-by-step instructions. Quick, easy, and delicious homemade recipe with simple ingredients.`} />

            {/* OpenGraph */}
            <meta property="og:title" content={`${recipe.title} - Must-Try Recipe | Ladle`} />
            <meta property="og:description" content={`Discover this amazing ${recipe.title} recipe. Perfect for ${recipe.tags?.join(', ')}. Save and cook later!`} />
            <meta property="og:image" content={recipe.image} />
            <meta property="og:type" content="recipe" />
            <meta property="og:url" content={window.location.href} />

            {/* Keywords */}
            <meta name="keywords" content={`${recipe.title}, easy recipe, homemade, best recipe, quick meals, healthy cooking, ${recipe.cuisines?.join(', ')}, ${recipe.tags?.join(', ')}`} />

            {/* Recipe-specific structured data */}
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "Recipe",
                "name": recipe.title,
                "description": recipe.description,
                "keywords": recipe.tags?.join(','),
                "image": recipe.image,
                "cuisine": recipe.cuisines?.[0],
                "recipeCategory": recipe.tags?.[0]
              })}
            </script>
          </Helmet>

        )}

        {this.state.isMobile ?
          <ConsumerRecipeCardMbPublic recipe_id={this.state.recipe_id} type='consumer' user={this.props.user} tenant={this.props.tenant} navigate={this.props.navigate} /> :
          <ConsumerRecipeCardPublic recipe_id={this.state.recipe_id} type='consumer' user={this.props.user} tenant={this.props.tenant} navigate={this.props.navigate} />
        }
      </>
    );
  }
}

export default ConsumerViewRecipeConsumerPagePublic;