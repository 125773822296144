import React, { Component } from 'react';
import config from "../utils/config.js";
import {AzureOpenAI} from 'openai';
import { X } from 'lucide-react';

class ConsumerPersonaliseChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      userMessages: [{
        role: "assistant", content: "Hey there!"
      },
      {
        role: "assistant", content: "Want to add or replace an ingredient in this recipe? Just ask me!"
      }],
      promptMessages: [],
      loading: false,
      input: "",
      ingredientUpdated: false
    }
  }
  componentDidMount() {
    console.log("we think", this.props.recipe);
  }
  async changeRecipe(e) {
    this.setState({
      loading: true,
      input: ""
    })
    let ingredients = []
    this.props.recipe.ingredients_list.forEach((ingredient) => {
      ingredients.push(ingredient[0])
    })
    const promptTemplate = [];
    // const newIngredients = [];
    promptTemplate.push(...this.state.promptMessages, {
      role: 'assistant', content: `You're a sous chef assistant and always you sound like a professional chef. now your job is to help update user preferred ingredient, diet, cookware and answer questions like calories prep methods in the receipe provided. Only give information on recipe provided, Refrain from answering any other questio unless it's related to recipe such as calories etc
          Follow the steps below
          Step 0 : Always get user confirmation on what ingredient/cookware you're about change before changing/giving final output.
            Step 1 - User Asks a question, if it's about ingredient or cookware or dietery change, go to step 2 or otherwise answer
            Step 2 - If user says he wants to change a ingredient/cookware/dietery preference or don't have it.. based on the recipe name provided check if the ingredient is main ingredient (for example, lemon is main in lemon rice). if main goto step 3 or suggest a new ingredient without any additional texts and ask if you need to proceed
            Step 3 - If it's main ingredient your response should be -  Hey, lemon in lemon rice is a key ingredient. If I change this then it will become a new recipe. Would you want to continue?
            Step 4 - If user says yes, then update that ingredient with new one and return the ingredient. Make sure the ingredient change make sense.. like you cannot add sugar in traditional coffee right? and only change the one ingredient after suggesting it unless asked otherwise. For instance ask the user if they want to make changes to dietery preference or just the ingredient if the change requires dietery change unless just one. You can reject and let the user know if the ingredient doen't make sense.            important: When user says yes to change - return ingredient/cookware only as final response with ingredient: or Cookware: and importantly don't ask the user to give a ingredient or cookware, always you suggest those and ask if you can continue with the change
            important: When user says yes to change - return ingredient/cookware only as final response with ingredient: or Cookware:
              Remember you don't need to overthink as well. If user asks to remove cheese that doesn't me if need to make the dish dairy free. but then if recipe doesn't makes without it kindly say it and give options
            Example 
            Ingredient: new: 1/2 cup of white onion, old: old_id 
            if cookware
            Example 
            Cookware: new: Ladle, old: spoon 
            and don't add . or anything after old_id
            remember above is only example you're giving answers for specific ingrediet/cookware that user asks for the specific recipe provided
            think, follow steps and answer carefully. 
            
           }` })
    promptTemplate.push(...this.state.promptMessages, { role: 'user', content: `The recipe Name is ${this.props.recipe.title}, and description is ${this.props.recipe.description},} their calories are Low: ${this.props.recipe.calories.low}, high: ${this.props.recipe.calories.high}}` }) //simple value

    ingredients.forEach((index, ingre) => {
      promptTemplate.push(...this.state.promptMessages, { role: 'user', content: `The recipe steps are ${ingre}, ${index.name} their unique id is ${index.id}. consider this whole as one step` }) //simple value
    })
    this.props.recipe.steps_list.forEach((index, ingre) => {
      promptTemplate.push(...this.state.promptMessages, { role: 'user', content: `The recipe making instructions are ${index.text}` }) //simple value
    })
    this.props.recipe.equipments_list.forEach((index, ingre) => {
      promptTemplate.push(...this.state.promptMessages, { role: 'user', content: `The recipe making cookware & appliances  are ${index.name} and their type is ${index.type} ` }) //simple value
    })
    if (this.state.userMessages.length > 0) {
      promptTemplate.push(...this.state.promptMessages, ...this.state.userMessages)
    }
    promptTemplate.push(...this.state.promptMessages, { role: 'user', content: `${this.state.message}` }) //simple value
    this.setState({ userMessages: [...this.state.userMessages, { role: "user", content: this.state.message }] }) //simple value

    try {
      const azureApiKey = config.AZURE_KEY;
    
            const openai = new AzureOpenAI({
              endpoint: config.AZURE_ENDPOINT,
              apiKey: azureApiKey,
              dangerouslyAllowBrowser: true, // Set to true if you want to allow browser usage
              apiVersion:"2024-08-01-preview"
            });
      const chatCompletion = await openai.chat.completions.create({
        messages: promptTemplate,
        model: 'gpt-4o',
      });
      if ((chatCompletion.choices[0].message.content).toLowerCase().includes("ingredient:") || (chatCompletion.choices[0].message.content).toLowerCase().includes("ingredients:") || (chatCompletion.choices[0].message.content).toLowerCase().includes("cookwares:") || (chatCompletion.choices[0].message.content).toLowerCase().includes("cookware:")) {
        this.setState({
          loading: true
        })
        this.setState({ userMessages: [...this.state.userMessages, chatCompletion.choices[0].message] })
        this.setState({ userMessages: [...this.state.userMessages, { role: "assistant", content: "Quality check started... I'll update the ingredients and instructions if this <b>change request passes</b> our food quality test." }] })

        const ingredientString = chatCompletion.choices[0].message.content;

        const colonIndex = ingredientString.indexOf(':');

        const substring = ingredientString.substring(colonIndex + 1);

        const keyValuePairs = substring.split(', ');

        const oldPair = keyValuePairs.find(pair => pair.includes('old'));

        const oldValue = oldPair.split(': ')[1].trim();
        let newrecipe = this.props.recipe;
        let animatevalue;

        promptTemplate.push(...this.state.promptMessages, {
          role: 'user', content: `As a professional sous chef, generate a precise JSON response based on previously suggested modifications. Follow these key guidelines:

          Recipe Modification Rules:
          1. Update only confirmed changes from previous suggestions
          2. Maintain recipe integrity and culinary standards
          3. Use real, verified ingredients only
          4. Ensure all modifications reflect in steps and instructions
          5. Update title and description to match ingredient changes
          6. Adjust cookware and steps in parallel
          
          JSON Structure Requirements:
          {
            "title": "Recipe Name",
            "description": "Professional culinary description with accurate ingredient references",
            "ingredients": [
              {
                "text": "Precise measurement and ingredient",
                "updated": boolean
              }
            ],
            "steps": [
              {
                "text": "Action description",
                "instruction": "Detailed method",
                "duration": "time_in_seconds"
              }
            ],
            "cookware": [
              {
                "originalText": "Equipment name",
                "step": number,
                "quantity": number,
                "size": "size_specification",
                "notes": "Usage notes",
                "requiredModes": [],
                "id": "unique_identifier",
                "name": "Display name",
                "type": "cookware|appliance"
              }
            ]
          }
          
          Quality Standards:
          - Exact measurements and quantities
          - Professional culinary terminology
          - Logical step sequences
          - Proper equipment specifications
          - Accurate timing durations
          - Complete ingredient details
        ` })
        const chatCompletionnew = await openai.chat.completions.create({
          messages: promptTemplate,
          model: 'gpt-4o',
          response_format: { type: 'json_object' }
        });
        let newrecipeJson = JSON.parse(chatCompletionnew.choices[0].message.content);

        let currentRecipe = JSON.parse(localStorage.getItem('personalized_recipe'));

        // Update the necessary fields
        currentRecipe.personlized_recipe.steps_list = newrecipeJson.steps_list;
        currentRecipe.personlized_recipe.ingredients_list = newrecipeJson.ingredients_list;
        currentRecipe.personlized_recipe.equipments_list = newrecipeJson.equipments_list;
        currentRecipe.personlized_recipe.title = newrecipeJson.title;
        currentRecipe.personlized_recipe.description = newrecipeJson.description;


        // Save the updated recipe back to localStorage
        localStorage.setItem('personalized_recipe', JSON.stringify(currentRecipe));
        this.setState({ userMessages: [...this.state.userMessages, { role: "assistant", content: "Done!" }
        ] })

          this.setState({
            loading: false
          })
          window.location.reload();

      } else {
        this.setState({ userMessages: [...this.state.userMessages, chatCompletion.choices[0].message] }) //simple value
      }
      this.setState({ message: '' });
      this.setState({
        loading: false
      })
    } catch (error) {
      console.log('Error processing your request', error);
    }


  }
  render() {
    const messages = [
      { text: "Hey there! How are you doing?", time: "10:00 AM", sender: "user" },
      { text: "I'm good, thank you! How about you?", time: "10:05 AM", sender: "bot" }
    ];
    this.changeRecipe = this.changeRecipe.bind(this)

    return (
      <div className="max-w-lg mx-auto bg-white" style={{
        position: "sticky",
        top: "14%",
        boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
        overflowY: "none",
        height: "85vh"
      }}>
        <div className="flex flex-col bg-white rounded-3xl">
          <div className="p-6 rounded-t-2xl text-white font-bold text-center text-xl" style={{
            background: "linear-gradient(to right,  #0c5212 0%,  #3ba958 35%)"
          }}>
            Chat with our Sous Chef
          </div>
          <div className="flex-grow overflow-y-scroll" style={{
            height: "70vh",
            marginBottom: "40px"
          }}>
            <div className="flex flex-col space-y-2 p-4">
              {this.state.userMessages.map((message, index) => (
                <div key={index} className={`flex items-center ${message.role === 'user' ? 'self-end' : 'self-start'}`}>

                  <div className={`rounded-[10px] px-4 py-2 ${message.role === 'user' ? 'bg-green-600 text-white rounded-br-none' : 'bg-gray-300 rounded-bl-none'}`}
                    style={{
                      boxShadow: "0 2px 2px rgba(0,0,0,0.2)",
                      wordWrap: "break-word",
                      overflowWrap: "break-word"
                    }}>

                    <p style={{ wordWrap: "break-word", maxWidth: "330px", overflowWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: message.content }}>
                    </p>
                  </div>
                </div>
              ))}
              {this.state.loading && (
                <div className="mt-6">
                  <div className="typing">
                    <span className="circle scaling"></span>
                    <span className="circle  scaling"></span>
                    <span className="circle scaling"></span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="flex items-center p-3 bg-white sticky bottom-0 shadow-md"
            style={{
              maxHeight: '60px',
              boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.1)' // Subtle shadow inside the textarea
            }}
          >
            {this.state.loading ? <div className="flex-grow outline-none opacity-55 text-gray-400" style={{
              border: 'none',
              resize: 'none',
              outline: 'none',
              width: '100%', // Ensure full width within the container
              minHeight: '70px', // Minimum height to accommodate text
            }}>Processing...</div> : <textarea
              type="text"
              id='input-field'
              value={this.state.input}
              placeholder='Type your message...'
              wrap='soft'
              style={{
                border: 'none',
                resize: 'none',
                outline: 'none',
                margin: 0,
                padding: '1rem',
              }}

              onChange={(e) => this.setState({ message: e.target.value, input: e.target.value })}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();  // Prevent the default action
                  if (e.target.value === "") return
                  this.changeRecipe(e);
                }
              }}
              className="flex-grow outline-none"
            />}
            <button
              disabled={this.state.loading || this.state.input === ""}
              className="ml-4 bg-green-600 text-white rounded-full p-2"
              style={{
                width: "35px",
                height: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
              onClick={this.changeRecipe}
            >
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" className="w-6 h-6">
                <path d="M5 12h14M12 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  }
}


export default ConsumerPersonaliseChat;
